import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { RequestApproval } from '../models/RequestApproval';
import {
  getAgendaType,
  getAll,
  getById,
  getFileByName,
  getMeetingType,
} from '../services/RequestApprovalApi';
import Moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Clear, Edit } from '@material-ui/icons';
import { ArrowBackIos } from '@material-ui/icons';
import { MeetingType } from '../models/MeetingType';
import { MeetingTypeDetail } from '../models/MeetingTypeDetail';
import { DeptRelation } from '../models/DeptRelation';
import { AgendaType } from '../models/AgendaType';
import { AgendaTypeDetail } from '../models/AgendaTypeDetail';
import { MeetingSchedule } from '../models/MeetingSchedule';
import { getRequestApprovalDept } from '../services/DeptRelationAPI';
import { RequestApprovalEnum } from '../models/RequestApprovalEnum';
import { useForm } from 'react-hook-form';
import { getMeetingScheduleByMeetingType } from '../services/MeetingScheduleAPI';
import { getProfileByEmpId } from '../services/EmployeeAPI';
import { EmployeeProfileModel } from '../models/EmployeeProfileModel';
import { RequestApprovalMapper } from '../models/RequestApprovalMapper';

const RequestDetail = (prop: Prop) => {
  const { setValue, getValues } = useForm<Detail>();
  const [file, setFile] = useState<File>();
  const [meetingType, setMeetingType] = useState<MeetingType>();
  const [selectedMeeting, setSelectedMeeting] =
    useState<MeetingTypeDetail | null>();
  const [deptRelation, setDeptRelation] = useState<DeptRelation[]>();
  const [agendaTypeList, setAgendaTypeList] = useState<AgendaType | null>();
  const [selectedAgenda, setSelectedAgenda] =
    useState<AgendaTypeDetail | null>();
  const [selectedDept, setSelectedDept] = useState<DeptRelation | null>();
  const [meetingSchedule, setMeetingSchedule] = useState<
    MeetingSchedule[] | null
  >();
  const [selectedMeetingSchedule, setSelectedMeetingSchedule] =
    useState<MeetingSchedule | null>();
  const [requestApproval, setRequestApproval] =
    useState<RequestApprovalMapper>();
  const [employeeDetail, setEmployeeDetail] = useState<EmployeeProfileModel>();
  const [division, setDivision] = useState<DeptRelation>();
  const [relatedDepartment, setRelatedDepartment] = useState<DeptRelation[]>(
    []
  );

  useEffect(() => {
    Promise.all([getMeetingType(), getRequestApprovalDept()]).then(
      ([meetingType, dept]) => {
        setMeetingType(meetingType.data);
        setDeptRelation(dept.data);
      }
    );
  }, []);

  useEffect(() => {
    if (meetingType && deptRelation) {
      if (prop && prop.view && prop.view !== 'new' && prop.id) {
        getById(+prop.id).then((value) => {
          setRequestApproval(value.data);
          if (value.data.fileName) setValue('fileName', value.data.fileName);
          if (value.data.id) setValue('id', value.data.id);
          if (value.data.meetingType) {
            setupMeeting(value.data.meetingType);
            if (value.data.agendaType) {
              setValue('agendaType', value.data.agendaType);
            }
          }

          if (value.data.subject) setValue('subject', value.data.subject);
          if (value.data.comment) setValue('comment', value.data.comment);
          if (value.data.meetingId) setValue('meetingId', value.data.meetingId);
          if (value.data.meetingSchedule)
            setValue('meetingSchedule', value.data.meetingSchedule);

          if (value.data.meetingSchedule)
            setSelectedMeetingSchedule(value.data.meetingSchedule);

          if (value.data.department) {
            setupDept(value.data.department);
          }

          if (value.data.status) setValue('status', value.data.status);

          if (value.data.createBy) setupEmployeeDetail(value.data.createBy);

          if (value.data.presentationFileName)
            setValue('presentationFileName', value.data.presentationFileName);
          if (value.data.memoFileName)
            setValue('memoFileName', value.data.memoFileName);
          if (value.data.powerpointFileName)
            setValue('powerpointFileName', value.data.powerpointFileName);

          if (value.data.createDate)
            setValue('createDate', value.data.createDate);
        });

        // getAllFileName(+prop.id).then((value) => {
        //   setAllFileName(value.data);
        // });
      }
    }
  }, [meetingType, deptRelation]);

  useEffect(() => {
    if (employeeDetail && deptRelation) {
      const division = deptRelation.find(
        (item) => item.deptID === employeeDetail.deptRelation?.division
      );
      setDivision(division);
    }
  }, [employeeDetail, deptRelation]);

  useEffect(() => {
    if (requestApproval && deptRelation) {
      if (
        Array.isArray(requestApproval.relatedDepartment) &&
        Array.isArray(deptRelation)
      ) {
        let data = requestApproval.relatedDepartment.map((item) => {
          return deptRelation.find((dept) => {
            return item === dept.deptID;
          })!;
        });

        if (data) {
          setRelatedDepartment(data);
        }
      }
    }
  }, [requestApproval, deptRelation]);

  const setupMeeting = (id: string) => {
    const c = meetingType?.meetingTypes?.find((v) => v.id === id);
    if (c) {
      setSelectedAgenda(null);
      setSelectedMeetingSchedule(null);
      setSelectedMeeting(c);
      findAgendaType(id);
      // findMeetingSchedule(id);
    } else {
      setSelectedMeeting(null);
    }
  };

  const findAgendaType = (id: string) => {
    getAgendaType(id)
      .then((value) => {
        console.log('Agenda Type ==> ', value.data);
        setAgendaTypeList(value.data);

        let agendaTypeData = getValues('agendaType');

        if (agendaTypeData && value.data) {
          const c = value.data?.agendaTypes?.find(
            (v) => v.id === agendaTypeData
          );
          if (c) {
            setSelectedAgenda(c);
          }
        }
      })
      .catch((err) => {
        setAgendaTypeList(null);
      });
  };

  const setupAgenda = (id: string) => {
    const c = agendaTypeList?.agendaTypes?.find((v) => v.id === id);
    if (c) {
      setSelectedAgenda(c);
    }
  };

  // const findMeetingSchedule = (id: string) => {
  //   getMeetingScheduleByMeetingType(id, getValues('id'))
  //     .then((value) => {
  //       console.log('Meeting Schedule ==> ', value.data);
  //       setMeetingSchedule(value.data);

  //       let meetingIdData = getValues('meetingId');

  //       if (meetingIdData && value.data) {
  //         const c = value.data?.find((v) => v.meetingId === meetingIdData);
  //         if (c) {
  //           setSelectedMeetingSchedule(c);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       setMeetingSchedule(null);
  //     });
  // };

  const setupMeetingSchedule = (id: number) => {
    const c = meetingSchedule?.find((v) => v.id === id);
    if (c) {
      setSelectedMeetingSchedule(c);
    }
  };

  const setupDept = (id: string) => {
    const c = deptRelation?.find((v) => v.deptID === id);
    if (c) {
      setSelectedDept(c);
    }
  };

  const setupEmployeeDetail = (empId: string) => {
    getProfileByEmpId(empId)
      .then((res) => {
        setEmployeeDetail(res.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const downFileByName = (fileName: string | undefined, fileType: string) => {
    if (fileName) {
      getFileByName(getValues('id'), fileName, fileType).then((res) => {
        saveAs(res.data, fileName);
      });
    }
  };

  return (
    <div className="container-fluid">
      {/* <div className="row mt-3 mb-2">
        <div
          className="col-auto is-link"
          onClick={() => prop.callBack('table', '0')}
        >
          <IconButton className="px-0" size="small">
            <ArrowBackIos />
          </IconButton>
          <small className="text-muted">กลับ</small>
        </div>
      </div> */}

      <div
        className="row border-bottom border-warning mt-4"
        style={{ paddingBottom: '1rem' }}
      >
        <h4 className="col-auto">
          <strong>Request Detail</strong>
        </h4>
        {getValues('status') && (
          <h6 className={`${getValues('status')} col-auto col-form-label`}>
            {getValues('status')}
          </h6>
        )}
      </div>

      <div className="row mt-3">
        <h6 className="col-2-5 d-flex justify-content-end">ฝ่าย:</h6>
        <h6 className="col-3">
          {' '}
          {employeeDetail?.deptRelation?.deptName
            ? employeeDetail?.deptRelation?.deptName
            : '-'}
        </h6>
        <h6 className="col-2 d-flex justify-content-end">Request Date:</h6>
        <h6 className="col-4">
          {Moment(getValues('createDate')).format('DD MMM YYYY')}
        </h6>
      </div>

      <div
        className="row  border-bottom border-warning mt-2"
        style={{ paddingBottom: '1rem' }}
      >
        <h6 className="col-2-5 d-flex justify-content-end">สายงาน:</h6>
        <h6 className="col-3">
          {division?.deptName ? division?.deptName : '-'}
        </h6>
        <h6 className="col-2 d-flex justify-content-end">Requester:</h6>
        <h6 className="col-4" style={{ paddingRight: '0rem' }}>
          {employeeDetail?.nameEn ? employeeDetail?.nameEn : '-'}
        </h6>
        {/* <h6 className="col-1 d-flex justify-content-end">status:</h6>
        <h6 className="col-1">{getValues('status')}</h6> */}
      </div>

      <div className="border-bottom border-warning mt-3">
        <h6>รายละเอียด</h6>
        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">Meeting:</h6>
          <h6 className="col-3">{selectedMeeting?.subject}</h6>
          {/* <h6 className="col-2 d-flex justify-content-end">status:</h6>
          <h6 className="col-3">{getValues('status')}</h6> */}
          <h6 className="col-2 d-flex justify-content-end">Meeting Date:</h6>
          <h6 className="col-4">
            {selectedMeetingSchedule?.id
              ? Moment(selectedMeetingSchedule?.meetingDate).format(
                  'DD MMM YYYY HH:mm'
                )
              : ''}
          </h6>
        </div>

        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">
            Department/Group/Division:
          </h6>
          <h6 className="col-3">{selectedDept?.deptName}</h6>
          {/* <h6 className="col-2 d-flex justify-content-end">Meeting Date:</h6>
          <h6 className="col-4">
            {selectedMeetingSchedule?.id
              ? Moment(selectedMeetingSchedule?.meetingDate).format(
                  'DD MMM YYYY HH:mm'
                )
              : ''}
          </h6> */}
          <h6 className="col-2 d-flex justify-content-end">
            กำหนดวันหารือ ผจก:
          </h6>
          <h6 className="col-4">
            {selectedMeetingSchedule?.id
              ? Moment(selectedMeetingSchedule?.mdDate).format(
                  'DD MMM YYYY HH:mm'
                )
              : ''}
          </h6>
        </div>

        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">Agenda Type:</h6>
          <h6 className="col-3">{selectedAgenda?.descr}</h6>
          {/* <h6 className="col-2 d-flex justify-content-end">
            กำหนดวันหารือ ผจก:
          </h6>
          <h6 className="col-4">
            {selectedMeetingSchedule?.id
              ? Moment(selectedMeetingSchedule?.mdDate).format(
                  'DD MMM YYYY HH:mm'
                )
              : ''}
          </h6> */}
          <h6 className="col-2 d-flex justify-content-end">
            กำหนดวันหารือ ประธาน:
          </h6>
          <h6 className="col-4">
            {selectedMeetingSchedule?.id
              ? Moment(selectedMeetingSchedule?.chairManDate).format(
                  'DD MMM YYYY HH:mm'
                )
              : ''}
          </h6>
        </div>

        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">Agenda:</h6>
          <h6 className="col-9">{getValues('subject')}</h6>
          {/* <h6 className="col-2 d-flex justify-content-end">
            กำหนดวันหารือ ประธาน:
          </h6>
          <h6 className="col-4">
            {selectedMeetingSchedule?.id
              ? Moment(selectedMeetingSchedule?.chairManDate).format(
                  'DD MMM YYYY HH:mm'
                )
              : ''}
          </h6> */}
        </div>

        {/* <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">Attach File:</h6>
          {getValues('fileName') ? (
            <h6 className="col-3 is-link text-info">{getValues('fileName')}</h6>
          ) : (
            <h6 className="col-3 ">-</h6>
          )}
        </div> */}

        {/* {allFileName && allFileName.length > 0 && (
          <div className="mb-3">
            {allFileName?.map((name, index) => {
              return (
                <div key={index + `${name}`} className="row mb-1">
                  <div className="col-2-5 d-flex justify-content-end col-form-label">
                    <label>{index === 0 ? 'Attach File:' : ''}</label>
                  </div>
                  <label
                    className="is-link text-info col-form-label col-4"
                    onClick={(e) => downFileByName(name)}
                  >
                    {name}
                  </label>
                </div>
              );
            })}
          </div>
        )} */}

        {relatedDepartment && relatedDepartment.length > 0 ? (
          <div className="mb-3">
            {relatedDepartment?.map((item, index) => {
              return (
                <div key={index + `${item}`} className="row mb-1">
                  <div className="col-2-5 d-flex justify-content-end col-form-label">
                    <label>{index === 0 ? 'Related Department:' : ''}</label>
                  </div>
                  <label className="col-form-label col-4">
                    {item.deptName}
                  </label>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="row  mb-2" style={{ paddingBottom: '1rem' }}>
            <h6 className="col-2-5 d-flex justify-content-end">
              Related Department:
            </h6>
            <h6 className="col-4">-</h6>
          </div>
        )}

        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">
            Presentation File:
          </h6>
          {getValues('presentationFileName') ? (
            <h6
              className="col-7 is-link text-info"
              onClick={(e) =>
                downFileByName(
                  getValues('presentationFileName'),
                  'presentation'
                )
              }
            >
              {getValues('presentationFileName')}
            </h6>
          ) : (
            <h6 className="col-3 ">-</h6>
          )}
        </div>

        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">Memo File:</h6>
          {getValues('memoFileName') ? (
            <h6
              className="col-7 is-link text-info"
              onClick={(e) => downFileByName(getValues('memoFileName'), 'memo')}
            >
              {getValues('memoFileName')}
            </h6>
          ) : (
            <h6 className="col-3 ">-</h6>
          )}
        </div>

        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">
            Powerpoint File:
          </h6>
          {getValues('powerpointFileName') ? (
            <h6
              className="col-7 is-link text-info"
              onClick={(e) =>
                downFileByName(getValues('powerpointFileName'), 'powerpoint')
              }
            >
              {getValues('powerpointFileName')}
            </h6>
          ) : (
            <h6 className="col-3 ">-</h6>
          )}
        </div>

        <div className="row  mt-2" style={{ paddingBottom: '1rem' }}>
          <h6 className="col-2-5 d-flex justify-content-end">Comment:</h6>
          <h6 className="col-9">{getValues('comment')}</h6>
        </div>
      </div>

      <div className="mt-3">
        <h6>ประวัตืการดำเนินงาน</h6>
        <div className="row mb-3">
          <table className="table">
            <thead
              className="table-header"
              style={{ borderBottom: '3px solid #5e5a5a' }}
            >
              <tr>
                <th></th>
                <th>ACTION BY</th>
                <th>POSITION</th>
                <th>ACTION</th>
                <th>DATE</th>
                <th>COMMENT</th>
              </tr>
            </thead>
            {requestApproval?.transactionLogMappers?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={6} className="text-center">
                    <h5>
                      <b>ไม่พบข้อมูล</b>
                    </h5>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {requestApproval?.transactionLogMappers?.map((it, index) => {
                  return (
                    <tr key={it.id}>
                      <td>{index + 1}</td>
                      <td>{it.actor ? it.actor.nameEn : '-'}</td>
                      <td>-</td>
                      <td>{it.action}</td>
                      <td>
                        {it?.createDate
                          ? Moment(it?.createDate).format('DD MMM YYYY HH:mm')
                          : '-'}
                      </td>
                      <td>{it.comment ? it.comment : '-'}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>

      <div className="row d-flex justify-content-end mb-3 mt-4">
        <div className="col-auto  ">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => prop.callBack('table', '0')}
          >
            <strong>BACK</strong>
          </button>
        </div>
        <div className="col-auto  ">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={() => prop.callBack('edit', prop.id)}
          >
            <strong>EDIT</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

type Prop = {
  view: string | undefined;
  id: string | undefined;
  callBack: (view: string | undefined, id: string | undefined) => void;
};

type Detail = {
  id: number;
  meetingId?: number;
  meetingSchedule?: MeetingSchedule;
  meetingType?: string;
  subject: string;
  meetingDate: Date;
  meetingStartTime: Date;
  meetingEndTime: Date;
  agendaId: number;
  comment: string;
  department?: string;
  agendaType?: string;
  status?: RequestApprovalEnum;
  fileName?: string;
  presentationFileName?: string;
  memoFileName?: string;
  powerpointFileName?: string;
  createDate?: Date;
};

export default RequestDetail;
