import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';
import { DeptRelation } from '../models/DeptRelation';

const getRequestApprovalDept = (): Promise<AxiosResponse<DeptRelation[]>> => {
  return DIGITAL_FLOW_API.get<DeptRelation[]>(`/department/request-approval`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

export { getRequestApprovalDept };
