import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    addMeetingSchedule,
    deleteMeetingSchedule,
    getMeetingSchedules,
    updateMeetingSchedule,
} from '../../services/MeetingScheduleAPI';
import {MeetingSchedule} from '../../models/MeetingSchedule';
import {Edit, Delete, Save, Close, Clear} from '@material-ui/icons';
import {MeetingTypeDetail} from '../../models/MeetingTypeDetail';
import {getMeetingType} from '../../services/RequestApprovalApi';

import {IconButton} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {MeetingDetailModel} from '../../models/MeetingDetailModel';
import {getMeetingDetails} from '../../services/MeetingAPI';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import {DatePicker} from '@mui/lab';
import Moment from 'moment';
import {ResponseEnum} from '../../utils/response.enum';
import showToast from '../../shared/toast';
import Swal from 'sweetalert2';
import confirmPopup from '../../shared/confirmPopup';
import {Modal, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {Message} from '../../Message';

const MeetingScheduleList = (prop: Prop) => {
    const {register, handleSubmit, setValue, getValues} = useForm<FormValue>();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState<number>(prop.page);

    const [meetingTypeDropdown, setMeetingTypeDropdown] =
        useState<MeetingTypeDetail[]>();
    const [dateTimeDropdownMap, setDateTimeDropdownMap] =
        useState<DateTimeDropdownMapper[]>();

    // Defining a state named rows
    const [rows, setRows] = useState<MeetingSchedule[]>([]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [selectedMeetingType, setSelectedMeetingType] = useState<string>();
    const [selectedMeetingDate, setSelectedMeetingDate] = useState<Date>();
    const [meetingDetail, setMeetingDetail] = useState<MeetingDetailModel[]>([]);
    const [meetingTime, setMeetingTime] = useState<String[]>([]);
    const [selectedMDDate, setSelectedMDDate] = useState<Date>(new Date());
    const [selectedMDStartTime, setSelectedMDStartTime] = useState<Date>(
        new Date()
    );
    const [selectedMDEndTime, setSelectedMDEndTime] = useState<Date>(new Date());
    const [selectedChairManDate, setSelectedChairManDate] = useState<Date>(
        new Date()
    );
    const [selectedChairManStartTime, setSelectedChairManStartTime] =
        useState<Date>(new Date());
    const [selectedChairManEndTime, setSelectedChairManEndTime] = useState<Date>(
        new Date()
    );
    const [selectedMeetingId, setSelectedMeetingId] = useState<number>();

    useEffect(() => {
        Promise.all([
            getMeetingType(),
            getMeetingSchedules(currentPage - 1, pageSize),
        ])
            .then(([meetingType, schedules]) => {
                setMeetingTypeDropdown(meetingType.data.meetingTypes);
                setPage(schedules.data.totalPages || 1);
                if (schedules.data.content !== undefined) {
                    const data = schedules.data.content;
                    setRows([...data]);
                }
            })
            .catch(() => {
                setMeetingTypeDropdown([]);
                setPage(1);
                setRows([]);
            });
    }, [currentPage, pageSize]);

    useEffect(() => {
        const dateTimeDropdown = rows
            .map((it) => it.meetingType)
            .filter((value, index, self) => self.indexOf(value) === index)
            .filter((it) => it !== undefined)
            .map((it) => {
                if (it) return getMeetingDetails(it);
                return null;
            })
            .filter((it) => it);

        Promise.all(dateTimeDropdown).then((res) => {
            const map = res.map((it) => {
                return {
                    meetingType:
                        it?.request.responseURL.split('?')[1].split('=')[1] || '',
                    dropdown: it?.data || [],
                };
            });
            setDateTimeDropdownMap(map);
        });
    }, [rows]);

    const onPage = (event: any, value: number) => {
        setCurrentPage(value);
    };

    const handleAddRow = () => {
        const item: MeetingSchedule = {
            id: undefined,
            meetingType: '',
            meetingDate: new Date(),
            meetingStartTime: new Date(new Date().setHours(0, 0, 0, 0)),
            meetingEndTime: new Date(new Date().setHours(0, 0, 0, 0)),
            mdDate: new Date(),
            mdStartTime: new Date(new Date().setHours(0, 0, 0, 0)),
            mdEndTime: new Date(new Date().setHours(0, 0, 0, 0)),
            chairManDate: new Date(),
            chairManStartTime: new Date(new Date().setHours(0, 0, 0, 0)),
            chairManEndTime: new Date(new Date().setHours(0, 0, 0, 0)),
            isEdit: true,
        };
        const currentRows = [...rows];
        currentRows.push(item);
        setRows([...currentRows]);
    };

    const handleRemoveRow = (id: number | undefined, idx: number) => {
        if (id) {
            const isConfirm = confirmPopup(
                'Are you sure?',
                'You want to delete this schedule.'
            );
            isConfirm.then((res) => {
                if (res.isConfirmed) {

                    deleteMeetingSchedule(id).then(
                        (res) => {
                            if (res.data?.errorMsg?.includes('could not execute statement')) {
                                popup(false, 'This schedule used by request');
                            }else{
                                const currentRows = [...rows];
                                currentRows.splice(idx, 1);
                                setRows([...currentRows]);
                                popup(true, '');
                            }
                        },
                        () => {
                            popup(true, '');
                        }
                    );
                } else if (res.dismiss === Swal.DismissReason.cancel) {
                }
            });
        } else {
            const currentRows = [...rows];
            currentRows.splice(idx, 1);
            setRows([...currentRows]);
        }
    };

    const handleEdit = (index: number) => {
        rows[index].isEdit = true;
        setRows([...rows]);
    };

    const handleSave = (index: number) => {
        rows[index].isEdit = false;
        setRows([...rows]);

        const id = rows[index].id ? rows[index].id : undefined;
        if (id !== undefined) {
            updateMeetingSchedule(id, rows[index]).then(
                () => {
                    setRows([...rows]);
                    popup(true, '');
                },
                () => {
                    popup(false, '');
                }
            );
        } else {
            addMeetingSchedule(rows[index]).then(
                () => {
                    setRows([...rows]);
                    popup(true, '');
                },
                () => {
                    popup(false, '');
                }
            );
        }
    };

    const handleClose = (index: number) => {
        rows[index].isEdit = false;
        setRows([...rows]);

        if (rows[index].id === undefined) {
            const currentRows = [...rows];
            currentRows.splice(index, 1);
            setRows([...currentRows]);
        }
    };

    const handleSelectChange = (
        e: ChangeEvent<HTMLSelectElement>,
        index: number
    ) => {
        const {name, value} = e.target;
        const list = [...rows];

        if (name === 'meetingDate' || name === 'meetingTime') {
            setMeetingDateTime(list, index, +value);
        } else {
            (list as any)[index][name] = value;
        }
        setRows(list);
    };

    const setMeetingDateTime = (
        list: MeetingSchedule[],
        index: number,
        value: number
    ) => {
        (list as any)[index].meetingId = value;
        if (dateTimeDropdownMap) {
            const dropdown = dateTimeDropdownMap?.find(
                (it) => it.meetingType === list[index].meetingType
            )?.dropdown;

            const meetingDate = dropdown?.find((m) => m.id === value)?.rawDate;
            const meetingStartTime = dropdown?.find((m) => m.id === value)?.from;
            const meetingEndTime = dropdown?.find((m) => m.id === value)?.to;
            list[index].meetingDate = meetingDate
                ? new Date(meetingDate)
                : list[0].meetingDate;

            list[index].meetingStartTime = meetingStartTime
                ? convertDateToTime(meetingStartTime)
                : list[0].meetingStartTime;

            list[index].meetingEndTime = meetingEndTime
                ? convertDateToTime(meetingEndTime)
                : list[0].meetingEndTime;
        }
    };

    function convertDateToTime(time: string): Date {
        return new Date(
            `${new Date().getDay()}-${new Date().getMonth()}-${new Date().getFullYear()} ${time}:00`
        );
    }

    const handleAddMeeting = () => {
        console.log('Here');

        setIsShowModal(true);
        setValue('id', undefined);
        setValue('meetingType', '');
        setValue('meetingDate', new Date());
        setValue('meetingStartTime', new Date(new Date().setHours(0, 0, 0, 0)));
        setValue('meetingEndTime', new Date(new Date().setHours(0, 0, 0, 0)));
        setValue('mdDate', new Date());
        setValue('mdStartTime', new Date(new Date().setHours(0, 0, 0, 0)));
        setValue('mdEndTime', new Date(new Date().setHours(0, 0, 0, 0)));
        setValue('chairManDate', new Date());
        setValue('chairManStartTime', new Date(new Date().setHours(0, 0, 0, 0)));
        setValue('chairManEndTime', new Date(new Date().setHours(0, 0, 0, 0)));

        setSelectedMDDate(new Date());
        setSelectedMDStartTime(new Date(new Date().setHours(0, 0, 0, 0)));
        setSelectedMDEndTime(new Date(new Date().setHours(0, 0, 0, 0)));
        setSelectedChairManDate(new Date());
        setSelectedChairManStartTime(new Date(new Date().setHours(0, 0, 0, 0)));
        setSelectedChairManEndTime(new Date(new Date().setHours(0, 0, 0, 0)));
        setSelectedMeetingType('');
        setSelectedMeetingDate(new Date());
        setSelectedMeetingId(0);
    };

    const handleSelectChangeMeeting = (e: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = e.target;

        if (name === 'meetingDate' || name === 'meetingTime') {
            setValue('meetingId', +value);
            setSelectedMeetingId(+value);
            setMeetingDate(+value);
        } else if (name === 'meetingType') {
            setSelectedMeetingType(value);
            setValue('meetingType', value);
            getMeetingDetails(value)
                .then((res) => {
                    setMeetingDetail(res.data);
                    const map = res.data.map((it) => {
                        return {
                            meetingType: value,
                            dropdown: res.data || [],
                        };
                    });
                    setDateTimeDropdownMap(map);
                })
                .catch((err) => {
                    console.log('err');
                });
        }
    };

    const setMeetingDate = (value: number) => {
        if (dateTimeDropdownMap) {
            const dropdown = dateTimeDropdownMap?.find(
                (it) => it.meetingType === getValues('meetingType')
            )?.dropdown;

            const meetingDate = dropdown?.find((m) => m.id === value)?.rawDate;
            const meetingStartTime = dropdown?.find((m) => m.id === value)?.from;
            const meetingEndTime = dropdown?.find((m) => m.id === value)?.to;

            if (meetingDate) {
                setValue('meetingDate', new Date(meetingDate));
                setSelectedMeetingDate(new Date(meetingDate));
            }

            if (meetingStartTime && meetingDate) {
                setValue(
                    'meetingStartTime',
                    convertMeetingDateToTime(new Date(meetingDate), meetingStartTime)
                );
            }
            if (meetingEndTime && meetingDate) {
                setValue(
                    'meetingEndTime',
                    convertMeetingDateToTime(new Date(meetingDate), meetingEndTime)
                );
            }
        }
    };

    function convertMeetingDateToTime(meetingDate: Date, time: string): Date {
        let timeDetail = time.split(':');
        if (timeDetail) {
            meetingDate.setHours(+timeDetail[0]);
            meetingDate.setMinutes(+timeDetail[1]);
        }
        return meetingDate;
    }

    const onSubmit = () => {
        let meetingType = getValues('meetingType');
        let meetingId = getValues('meetingId');
        if (!meetingType || !meetingId) {
            popup(false, Message.SAVE_FAIL_INVALID_FORM);
            return;
        }

        let id = getValues('id');
        if (id !== undefined) {
            updateMeetingSchedule(id, getValues()).then(
                (res) => {
                    if (res.status === 200) {
                        popup(true, '');
                        getMeetinfList();
                    } else {
                        popup(false, '');
                    }
                },
                () => {
                    popup(false, '');
                }
            );
        } else {
            addMeetingSchedule(getValues()).then(
                (res) => {
                    if (res.status === 200) {
                        popup(true, '');
                        getMeetinfList();
                    } else {
                        popup(false, '');
                    }
                },
                () => {
                    popup(false, '');
                }
            );
        }
    };

    const handleEditMeeting = (index: number) => {
        let data = rows[index];
        console.log('data', data);
        setIsShowModal(true);
        setValue('id', data.id);
        setValue('meetingType', data.meetingType!);
        setValue('meetingDate', data.meetingDate!);
        setValue('meetingStartTime', new Date(data.meetingStartTime!));
        setValue('meetingEndTime', new Date(data.meetingEndTime!));
        setValue('mdDate', new Date(data.mdDate!));
        setValue('mdStartTime', new Date(data.mdStartTime!));
        setValue('mdEndTime', new Date(data.mdEndTime!));
        setValue('chairManDate', new Date(data.chairManDate!));
        setValue('chairManStartTime', new Date(data.chairManStartTime!));
        setValue('chairManEndTime', new Date(data.chairManEndTime!));
        setValue('meetingId', data.meetingId!);

        setSelectedMDDate(new Date(data.mdDate!));
        setSelectedMDStartTime(new Date(data.mdStartTime!));
        setSelectedMDEndTime(new Date(data.mdEndTime!));
        setSelectedChairManDate(new Date(data.chairManDate!));
        setSelectedChairManStartTime(new Date(data.chairManStartTime!));
        setSelectedChairManEndTime(new Date(data.chairManEndTime!));
        setSelectedMeetingType(data.meetingType!);
        setSelectedMeetingDate(new Date(data.meetingDate!));
        setSelectedMeetingId(data.meetingId!);

        setValue('meetingType', data.meetingType!);
        getMeetingDetails(data.meetingType!)
            .then((res) => {
                setMeetingDetail(res.data);
                const map = res.data.map((it) => {
                    return {
                        meetingType: data.meetingType!,
                        dropdown: res.data || [],
                    };
                });
                setDateTimeDropdownMap(map);
            })
            .catch((err) => {
                console.log('err');
            });
    };

    const getMeetinfList = () => {
        Promise.all([
            getMeetingType(),
            getMeetingSchedules(currentPage - 1, pageSize),
        ])
            .then(([meetingType, schedules]) => {
                setMeetingTypeDropdown(meetingType.data.meetingTypes);
                setPage(schedules.data.totalPages || 1);
                if (schedules.data.content !== undefined) {
                    const data = schedules.data.content;
                    setRows([...data]);
                }
                setIsShowModal(false);
            })
            .catch(() => {
                setMeetingTypeDropdown([]);
                setPage(1);
                setRows([]);
            });
    };

    const popup = (success: boolean, msg: string) => {
        return Swal.fire({
            text: msg,
            title: success ? 'บันทึกข้อมูลเรียบร้อยแล้ว' : 'บันทึกข้อมูลไม่สำเร็จ',
            icon: success ? 'success' : 'error',
            confirmButtonText: 'Close',
            customClass: {
                confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
        });
    };

    const renderTableData = () => {
        return (
            <>
                {rows?.map((row, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                {row.isEdit ? (
                                    <select
                                        className="form-select"
                                        onChange={(e) => handleSelectChange(e, index)}
                                        name="meetingType"
                                        value={rows[index].meetingType}
                                        style={{width: 100}}
                                    >
                                        <option value={undefined}>-- Type --</option>
                                        {meetingTypeDropdown?.map((value) => (
                                            <option key={value.id} value={value.id}>
                                                {value.id}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    row.meetingType
                                )}
                            </td>
                            <td>
                                {row.isEdit ? (
                                    <select
                                        className="form-select"
                                        onChange={(e) => handleSelectChange(e, index)}
                                        name="meetingDate"
                                        style={{width: 140}}
                                        value={row.meetingId}
                                    >
                                        <option value={undefined}>-- Date --</option>
                                        {dateTimeDropdownMap
                                            ?.find((it) => it.meetingType === row.meetingType)
                                            ?.dropdown?.map((value) => (
                                                <option key={value.id} value={value.id}>
                                                    {value.rawDate}
                                                </option>
                                            ))}
                                    </select>
                                ) : (
                                    Moment(row.meetingDate).format('DD MMM YYYY')
                                )}
                            </td>
                            <td>
                                {row.isEdit ? (
                                    <select
                                        className="form-select"
                                        onChange={(e) => handleSelectChange(e, index)}
                                        name="meetingTime"
                                        style={{width: 150}}
                                        value={row.meetingId}
                                    >
                                        <option value={undefined}>-- Time --</option>
                                        {dateTimeDropdownMap
                                            ?.find((it) => it.meetingType === row.meetingType)
                                            ?.dropdown?.map((value) => (
                                                <option key={value.id} value={value.id}>
                                                    {value.from} - {value.to}
                                                </option>
                                            ))}
                                    </select>
                                ) : (
                                    <div>
                                        {Moment(row.meetingStartTime).format('HH:mm')} -{' '}
                                        {Moment(row.meetingEndTime).format('HH:mm')}
                                    </div>
                                )}
                            </td>
                            <td>
                                {row.isEdit ? (
                                    <div className="dateTimePicker mt-1">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={row.mdDate}
                                                onChange={(newValue) => {
                                                    const updated = [...rows];
                                                    updated[index].mdDate = newValue
                                                        ? new Date(newValue)
                                                        : new Date();
                                                    setRows(updated);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        style={{
                                                            width: 150,
                                                            marginLeft: '.5rem',
                                                            height: 20,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <span>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={row.mdStartTime}
                            onChange={(newValue) => {
                                const updated = [...rows];
                                updated[index].mdStartTime = newValue
                                    ? new Date(newValue)
                                    : new Date();
                                setRows(updated);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    style={{width: 140, marginLeft: '.5rem'}}
                                />
                            )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={row.mdEndTime}
                            onChange={(newValue) => {
                                const updated = [...rows];
                                updated[index].mdEndTime = newValue
                                    ? new Date(newValue)
                                    : new Date();
                                setRows(updated);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    style={{width: 140, marginLeft: '.5rem'}}
                                />
                            )}
                        />
                      </LocalizationProvider>
                    </span>
                                    </div>
                                ) : (
                                    <div>
                                        {Moment(row.mdDate).format('DD MMM YYYY')}
                                        <span style={{marginLeft: '1.5rem'}}>
                      {Moment(row.mdStartTime).format('HH:mm')} -{' '}
                                            {Moment(row.mdEndTime).format('HH:mm')}
                    </span>
                                    </div>
                                )}
                            </td>
                            <td>
                                {row.isEdit ? (
                                    <div className="dateTimePicker mt-1">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={row.chairManDate}
                                                onChange={(newValue) => {
                                                    const updated = [...rows];
                                                    updated[index].chairManDate = newValue
                                                        ? new Date(newValue)
                                                        : new Date();
                                                    setRows(updated);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        style={{width: 150, marginLeft: '.5rem'}}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <span>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={row.chairManStartTime}
                            onChange={(newValue) => {
                                const updated = [...rows];
                                updated[index].chairManStartTime = newValue
                                    ? new Date(newValue)
                                    : new Date();
                                setRows(updated);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    style={{width: 140, marginLeft: '.5rem'}}
                                />
                            )}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            value={row.chairManEndTime}
                            onChange={(newValue) => {
                                const updated = [...rows];
                                updated[index].chairManEndTime = newValue
                                    ? new Date(newValue)
                                    : new Date();
                                setRows(updated);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    style={{width: 140, marginLeft: '.5rem'}}
                                />
                            )}
                        />
                      </LocalizationProvider>
                    </span>
                                    </div>
                                ) : (
                                    <div>
                                        {Moment(row.chairManDate).format('DD MMM YYYY')}
                                        <span style={{marginLeft: '1.5rem'}}>
                      {Moment(row.chairManStartTime).format('HH:mm')} -{' '}
                                            {Moment(row.chairManEndTime).format('HH:mm')}
                    </span>
                                    </div>
                                )}
                            </td>
                            <td>
                                {row.isEdit ? (
                                    <div>
                                        <IconButton
                                            className="mt-1 pt-1 mx-2"
                                            style={{width: '0.25rem', height: '0.25rem'}}
                                            onClick={() => handleSave(index)}
                                        >
                                            <Save/>
                                        </IconButton>

                                        <IconButton
                                            className="mt-1 pt-1 mx-2"
                                            style={{width: '0.25rem', height: '0.25rem'}}
                                            onClick={() => handleClose(index)}
                                        >
                                            <Close/>
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div>
                                        <IconButton
                                            className="mt-1 pt-1 mx-2"
                                            style={{width: '0.25rem', height: '0.25rem'}}
                                            // onClick={() => handleEdit(index)}
                                            onClick={() => handleEditMeeting(index)}
                                        >
                                            <Edit/>
                                        </IconButton>

                                        <IconButton
                                            className="mt-1 pt-1 mx-2"
                                            style={{width: '0.25rem', height: '0.25rem'}}
                                            onClick={() => handleRemoveRow(row.id, index)}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    </div>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </>
        );
    };

    const renderModal = () => {
        return (
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal
                        show={isShowModal}
                        onHide={() => setIsShowModal(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        animation={false}
                    >
                        <Modal.Header className="bg-warning">
                            <Modal.Title id="contained-modal-title-vcenter">
                                ADD MEETING
                            </Modal.Title>
                            <IconButton
                                className="mt-1 pt-1 mx-1 col-5"
                                style={{width: '0.25rem', height: '0.25rem'}}
                                onClick={() => setIsShowModal(false)}
                            >
                                <Clear/>
                            </IconButton>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-3">
                                <label
                                    className="col-2-5 d-flex justify-content-end col-form-label"
                                    htmlFor="meeting"
                                >
                                    Meeting Type <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-3">
                                    <select
                                        className="form-select"
                                        onChange={(e) => handleSelectChangeMeeting(e)}
                                        name="meetingType"
                                        value={selectedMeetingType}
                                    >
                                        <option value={undefined}>-- Type --</option>
                                        {meetingTypeDropdown?.map((value) => (
                                            <option key={value.id} value={value.id}>
                                                {value.id}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-2-5 d-flex justify-content-end col-form-label">
                                    Meeting Date <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-3">
                                    <select
                                        className="form-select"
                                        onChange={(e) => handleSelectChangeMeeting(e)}
                                        name="meetingDate"
                                        value={selectedMeetingId}
                                    >
                                        <option value={undefined}>-- Date --</option>
                                        {dateTimeDropdownMap
                                            ?.find((it) => it.meetingType === selectedMeetingType)
                                            ?.dropdown?.map((value) => (
                                                <option key={value.id} value={value.id}>
                                                    {value.rawDate}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <label
                                    className="col-2 d-flex justify-content-end col-form-label"
                                    htmlFor="meeting"
                                >
                                    Meeting TIME <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-4">
                                    <select
                                        className="form-select"
                                        onChange={(e) => handleSelectChangeMeeting(e)}
                                        name="meetingTime"
                                        value={selectedMeetingId}
                                    >
                                        <option value={undefined}>-- Time --</option>
                                        {dateTimeDropdownMap
                                            ?.find((it) => it.meetingType === selectedMeetingType)
                                            ?.dropdown?.map((value) => (
                                                <option key={value.id} value={value.id}>
                                                    {value.from} - {value.to}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-2-5 d-flex justify-content-end col-form-label">
                                    หารือ ผจก.
                                </label>
                                <div className="col-9 dateTimePicker px-1">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={selectedMDDate}
                                            inputFormat="dd MMM yyyy"
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    setValue('mdDate', newValue);
                                                    setSelectedMDDate(newValue);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    style={{
                                                        width: 150,
                                                        marginLeft: '.5rem',
                                                        height: 20,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                          value={selectedMDStartTime}
                          onChange={(newValue) => {
                              if (newValue) {
                                  setValue('mdStartTime', newValue);
                                  setSelectedMDStartTime(newValue);
                              }
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  size="small"
                                  style={{width: 140, marginLeft: '.5rem'}}
                              />
                          )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                          value={selectedMDEndTime}
                          onChange={(newValue) => {
                              if (newValue) {
                                  setValue('mdEndTime', newValue);
                                  setSelectedMDEndTime(newValue);
                              }
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  size="small"
                                  style={{width: 140, marginLeft: '.5rem'}}
                              />
                          )}
                      />
                    </LocalizationProvider>
                  </span>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label
                                    className="col-2-5 d-flex justify-content-end col-form-label"
                                    htmlFor="meeting"
                                >
                                    หารือ ปธ.
                                </label>
                                <div className="col-9 dateTimePicker px-1">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={getValues('chairManDate')}
                                            inputFormat="dd MMM yyyy"
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    setValue('chairManDate', newValue);
                                                    setSelectedChairManDate(newValue);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    style={{
                                                        width: 150,
                                                        marginLeft: '.5rem',
                                                        height: 20,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                          value={selectedChairManStartTime}
                          onChange={(newValue) => {
                              // setValue(
                              //   'chairManStartTime',
                              //   newValue ? new Date(newValue) : new Date()
                              // );
                              if (newValue) {
                                  setValue('chairManStartTime', newValue);
                                  setSelectedChairManStartTime(newValue);
                              }
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  size="small"
                                  style={{width: 140, marginLeft: '.5rem'}}
                              />
                          )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                          value={selectedChairManEndTime}
                          onChange={(newValue) => {
                              if (newValue) {
                                  setValue('chairManEndTime', newValue);
                                  setSelectedChairManEndTime(newValue);
                              }
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  size="small"
                                  style={{width: 140, marginLeft: '.5rem'}}
                              />
                          )}
                      />
                    </LocalizationProvider>
                  </span>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setIsShowModal(false)}>
                                Close
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                onClick={(e) => {
                                    onSubmit();
                                }}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </form>
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-between mt-3">
                <div className="col">
                    <label className="title">
                        <strong>กำหนดวันประชุมคณะกรรมการ</strong>
                    </label>
                </div>
            </div>

            <div className="row mb-3 mt-2">
                <div className="col">
                    <div className="col border border-warning"></div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="d-flex justify-content-end">
                    {/* <button className="btn btn-primary pb-0" onClick={handleAddRow}> */}
                    <button className="btn btn-primary pb-0" onClick={handleAddMeeting}>
                        <h6>
                            <strong>ADD</strong>
                        </h6>
                    </button>
                </div>
            </div>

            <div className="row mb-3 table-container">
                <table className="table text-center">
                    <thead className="table-header">
                    <tr>
                        <th></th>
                        <th>MEETING TYPE</th>
                        <th>MEETING DATE</th>
                        <th>MEETING TIME</th>
                        <th>หารือ ผจก.</th>
                        <th>หารือ ปธ.</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>{renderTableData()}</tbody>
                </table>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <Pagination
                        page={currentPage}
                        count={page}
                        variant="outlined"
                        onChange={onPage}
                        shape="rounded"
                    />
                </div>
            </div>
            {renderModal()}
        </div>
    );
};

type Prop = {
    page: number;
};

type DateTimeDropdownMapper = {
    meetingType: string;
    dropdown: MeetingDetailModel[];
};

type FormValue = {
    id: number | undefined;
    meetingId: number;
    meetingType: string;
    meetingDate: Date;
    meetingStartTime: Date;
    meetingEndTime: Date;
    mdDate: Date;
    mdStartTime: Date;
    mdEndTime: Date;
    chairManDate: Date;
    chairManStartTime: Date;
    chairManEndTime: Date;
};

export default MeetingScheduleList;
