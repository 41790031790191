import React, { createContext, useEffect, useMemo, useState } from 'react';
import { EmployeeProfileModel } from '../models/EmployeeProfileModel';
import { getSession } from '../services/EmployeeAPI';
import { isAdmin, loginAdmin } from '../services/LoginApi';
import { getUserRoles } from '../services/UserRoleAPI';
import { useQuery } from '@tanstack/react-query';


type UserContextType = {
    profile: EmployeeProfileModel | undefined;
    roles: string[];
    admin: boolean;
    boardAdmin: boolean;
    loading: boolean;
};

export const UserContext = createContext<UserContextType>({
    profile: undefined,
    roles: [],
    admin: false,
    boardAdmin: false,
    loading: false,
});

interface Props {
    children?: React.ReactNode;
}

export const UserProvider = ({ children }: Props) => {
    const [admin, setAdmin] = useState<boolean>(false);
    const [boardAdmin, setBoardAdmin] = useState<boolean>(false);
    const [userProfile, setUserProfile] = useState<EmployeeProfileModel | undefined>();
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const profileResponse = await getSession();
                setUserProfile(profileResponse.data);

                const isAdminResponse = await isAdmin();
                setAdmin(isAdminResponse.data);

                const rolesResponse = await getUserRoles();
                setUserRoles(rolesResponse.data);

                setBoardAdmin(rolesResponse.data.includes('BOARD_ADMIN'));
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const contextValue = useMemo(() => {
        console.log('provider data > ', userProfile);
        console.log('provider admin > ', admin);
        console.log('provider boardAdmin > ', boardAdmin);
        console.log('provider roles > ', userRoles);

        return ({
            profile: userProfile,
            roles: userRoles,
            admin,
            boardAdmin,
            loading
        });
    }, [userProfile, userRoles, admin, boardAdmin, loading]);

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
