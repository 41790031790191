import React, { ReactElement, useContext, useEffect, useState } from "react";
import "./App.css";
import "./style/Table.css";
import "./style/Font.css";
import HeadMenu from "./components/HeadMenu";
import SETLogo from "./images/SET50th logo.png";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import MyRequest from "./pages/MyRequest";
import AdminMeetingSchedule from "./pages/AdminMeetingSchedule";
import AdminPendingForApproval from "./pages/AdminPendingForApproval";
import PendingForApprove from "./pages/PendingForApprove";
import { loginAdmin } from "./services/LoginApi";
import { r } from "./routeConfig";
import AdminBoardMeeting from "./pages/AdminBoardMeeting";
import AdminAgenda from "./pages/AdminAgenda";
import { getUserRoles } from "./services/UserRoleAPI";
import PermissionPage from "./pages/PermissionPage";
import { UserContext, UserProvider } from "./providers/userProvider";
import Loading from "./shared/loading";
import PermissionChecker from "./services/PermissionChecker";

function App(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const [isDisplayHeader, setIsDisplayHeader] = useState<boolean>(true);

  const [admin, setAdmin] = useState<boolean>(false);
  const [boardAdmin, setBoardAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    const backPath = localStorage.getItem("back_path");
    if (backPath) {
      localStorage.removeItem("back_path");
      window.location.href = backPath;
    }

    setLoading(true);

    await getUserRoles()
      .then((rr) => setBoardAdmin(rr.data.includes("BOARD_ADMIN")))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.setItem("back_path", window.location.pathname);
          window.location.href = `${
            process.env.REACT_APP_API_ENDPOINT || ""
          }/oauth2${process.env.REACT_APP_ENV || ""}`;
        }
      });

    await loginAdmin()
      .then((adminResp) => setAdmin(adminResp))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.setItem("back_path", window.location.pathname);
          window.location.href = `${
            process.env.REACT_APP_API_ENDPOINT || ""
          }/oauth2${process.env.REACT_APP_ENV || ""}`;
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   setLoading(true);

    //   try {
    //     const rolesResponse = await getUserRoles();
    //     setBoardAdmin(rolesResponse.data.includes("BOARD_ADMIN"));

    //     const admin = await loginAdmin();
    //     setAdmin(admin);

    //     const backPath = localStorage.getItem("back_path");
    //     if (backPath) {
    //       localStorage.removeItem("back_path");
    //       window.location.href = backPath;
    //     }
    //   } catch (error) {
    //     if (error.response && error.response.status === 401) {
    //       localStorage.setItem("back_path", window.location.pathname);
    //       window.location.href = `${
    //         process.env.REACT_APP_API_ENDPOINT || ""
    //       }/oauth2${process.env.REACT_APP_ENV || ""}`;
    //     }
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    fetchData();
  }, []);

  useEffect(() => {
    if (location.pathname.includes("admin/agenda")) {
      setIsDisplayHeader(false);
    } else {
      setIsDisplayHeader(true);
    }
  }, [location.pathname]);

  if (loading) {
    return (
      <>
        <Loading isLoading={loading} />
      </>
    );
  }

  const newApp = () => {
    return (
      <div className="container-fluid">
        {isDisplayHeader ? (
          <div className="row Set-header align-items-center">
            <div className="col-2 d-flex justify-content-center">
              <img src={SETLogo} style={{ maxWidth: "7rem" }} alt="SET Logo" />
            </div>
            <div className="col-8 d-flex justify-content-center">
              <h1>
                <b>E-meeting</b>
              </h1>
            </div>
          </div>
        ) : (
          ""
        )}

        {isDisplayHeader ? (
          <div className="row">
            <HeadMenu isAdmin={admin} />
          </div>
        ) : (
          ""
        )}
        <div>
          <Switch>
            <Route path="/admin/meeting-schedule">
              <AdminMeetingSchedule admin={admin} />
            </Route>
            <Route path="/admin/pending-for-approval">
              <AdminPendingForApproval admin={admin} />
            </Route>
            <Route path="/my-request">
              <MyRequest />
            </Route>
            <Route path="/pending-for-approval/:id">
              <PendingForApprove />
            </Route>
            <Route path="/pending-for-approval">
              <PendingForApprove />
            </Route>
            <Route path="/admin/board-meeting">
              <AdminBoardMeeting admin={admin} boardAdmin={boardAdmin} />
            </Route>
            <Route path="/board-meeting">
              <AdminBoardMeeting admin={true} boardAdmin={true} />
            </Route>
            <Route path="/admin/agenda">
              <AdminAgenda admin={admin} boardAdmin={boardAdmin} />
            </Route>
            <Route path="/permission">
              <PermissionPage />
            </Route>
            <Redirect exact from="/" to="/my-request" />
          </Switch>
        </div>
      </div>
    );
  };

  return (
    <>
      <UserProvider>
        {/* <PermissionChecker /> */}
        {newApp()}
      </UserProvider>
    </>
  );
}

export default App;
