import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { r } from "../routeConfig";
import { ReactElement, useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./../style/Navbar.css";
import { relativeTimeRounding } from "moment";

const HeadMenu = (props: Prop): ReactElement => {
  const [menu, setMenu] = useState([
    {
      to: "",
      label: "",
    },
  ]);
  const location = useLocation();
  const history = useHistory();
  const param: { id: string } = useParams();

  useEffect(() => {
    setMenu(props.isAdmin ? r.admin : r.user);
  }, [props.isAdmin]);

  const reload = (path: string) => {
    if (location.pathname.includes(path)) {
      history.replace(path);
      window.location.reload();
    }
  };

  return (
    <>
      {props.isAdmin ? (
        <Navbar variant="dark" className="custom-nav">
          <Nav activeKey={location.pathname} className="me-auto">
            <Nav.Link
              as={Link}
              to="/my-request"
              className="nav-link-custom mx-2"
              onClick={() => reload("/my-request")}
            >
              <span
                className={`${
                  location.pathname === "/my-request" ? "nav-active" : ""
                }`}
              >
                My Requests
              </span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/board-meeting"
              className="me-auto"
              onClick={() => reload("/board-meeting")}
            >
              <span
                className={`${
                  location.pathname.includes("/board-meeting")
                    ? "nav-active"
                    : ""
                }`}
              >
                Board Meeting
              </span>
            </Nav.Link>
            <NavDropdown title="Admin" id="basic-nav-dropdown" className="mx-2">
              <NavDropdown.Item as={Link} to="/admin/meeting-schedule">
                Meeting Schedule
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/admin/pending-for-approval">
                Meeting MD
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/admin/board-meeting">
                Board Meeting
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar>
      ) : (
        <Navbar variant="dark" className="custom-nav">
          <Nav activeKey={location.pathname} className="me-auto">
            <Nav.Link
              as={Link}
              to="/my-request"
              className="mx-2"
              onClick={() => reload("/my-request")}
            >
              <span
                className={`${
                  location.pathname.includes("/my-request") ? "nav-active" : ""
                }`}
              >
                My Requests
              </span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/board-meeting"
              className="me-auto"
              onClick={() => reload("/board-meeting")}
            >
              <span
                className={`${
                  location.pathname.includes("/board-meeting")
                    ? "nav-active"
                    : ""
                }`}
              >
                Board Meeting
              </span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/pending-for-approval"
              className="mx-2"
              onClick={() => reload("/pending-for-approval")}
            >
              <span
                className={`${
                  location.pathname.includes("/pending-for-approval")
                    ? "nav-active"
                    : ""
                }`}
              >
                Pending For Approval
              </span>
            </Nav.Link>
          </Nav>
        </Navbar>
      )}
    </>
  );
};

type Prop = {
  isAdmin: boolean;
};

export default HeadMenu;
