import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IconButton, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import { Autocomplete } from '@mui/material';

import { Message } from '../Message';
import {
  addRequestApproval,
  deleteFileByName,
  getAgendaType,
  getById,
  getMeetingType,
  toUpload,
  updateRequestApproval,
  getCommandChain,
  getFileByName,
} from '../services/RequestApprovalApi';
import { MeetingType } from '../models/MeetingType';
import { MeetingTypeDetail } from '../models/MeetingTypeDetail';
import { getRequestApprovalDept } from '../services/DeptRelationAPI';
import { DeptRelation } from '../models/DeptRelation';
import { AgendaType } from '../models/AgendaType';
import { AgendaTypeDetail } from '../models/AgendaTypeDetail';
import { getMeetingScheduleByMeetingType } from '../services/MeetingScheduleAPI';
import { RequestApprovalEnum } from '../models/RequestApprovalEnum';
import { MeetingSchedule } from '../models/MeetingSchedule';
import {
  getSession,
  getProfileByEmpId,
  getAllManagers,
} from '../services/EmployeeAPI';
import { EmployeeProfileModel } from '../models/EmployeeProfileModel';
import { RequestAction } from '../models/RequestAction';
import { RequestApprovalMapper } from '../models/RequestApprovalMapper';
import { getMdProfile } from '../services/MeetingMDAPI';
import { MeetingMDModel } from '../models/MeetingMDModel';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { getDivisionApprover } from '../services/WorkPositionServiceAPI';

export interface IEmployeeTypeahead {
  label: string;
  value: string;
}

const RequestForm = (prop: Prop) => {
  const { register, handleSubmit, setValue, getValues, control } =
    useForm<FormValue>();
  // const [file, setFile] = useState<File[]>([]);
  const [meetingType, setMeetingType] = useState<MeetingType>();
  const [selectedMeeting, setSelectedMeeting] =
    useState<MeetingTypeDetail | null>();
  const [deptRelation, setDeptRelation] = useState<DeptRelation[]>([]);
  const [agendaTypeList, setAgendaTypeList] = useState<AgendaType | null>();
  const [selectedAgenda, setSelectedAgenda] =
    useState<AgendaTypeDetail | null>();
  const [selectedDept, setSelectedDept] = useState<DeptRelation | null>();
  const [meetingSchedule, setMeetingSchedule] = useState<
    MeetingSchedule[] | null
  >();
  const [selectedMeetingSchedule, setSelectedMeetingSchedule] =
    useState<MeetingSchedule | null>();
  const [allFileName, setAllFileName] = useState<string[]>();
  const [employeeDetail, setEmployeeDetail] = useState<EmployeeProfileModel>();
  const [division, setDivision] = useState<DeptRelation>();
  const [presentationFile, setPresentationFile] = useState<File>();
  const [memoFile, setMemoFile] = useState<File>();
  const [powerpointFile, setPowerpointFile] = useState<File>();
  const [presentationFileName, setPresentationFileName] = useState<string>();
  const [memoFileName, setMemoFileName] = useState<string>();
  const [powerpointFileName, setPowerpointFileName] = useState<string>();
  const [selectRelatedDept, setSelectRelatedDept] = useState<DeptRelation[]>(
    []
  );
  const [requestDate, setRequestDate] = useState<Date>(new Date());
  const [allDeptRelation, setAllDeptRelation] = useState<DeptRelation[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [requestApproval, setRequestApproval] =
    useState<RequestApprovalMapper>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const [sessionDetail, setSessionDetail] = useState<EmployeeProfileModel>();
  const [allCommandChain, setAllCommandChain] = useState<
    EmployeeProfileModel[]
  >([]);
  const [indexOfApprover, setIndexOfApprover] = useState<number>(0);
  const [actionStatus, setActionStatus] = useState<string>();
  const [mdProfile, setMdProfile] = useState<MeetingMDModel[]>([]);
  const [managers, setManagers] = useState<EmployeeProfileModel[]>([]);

  useEffect(() => {
    Promise.all([
      getMeetingType(),
      getRequestApprovalDept(),
      getSession(),
      getMdProfile(),
      getAllManagers(),
    ]).then(([meetingType, dept, emp, mdProfile, managers]) => {
      setMeetingType(meetingType.data);
      setDeptRelation(dept.data);
      setAllDeptRelation(dept.data);
      setEmployeeDetail(emp.data);
      setSessionDetail(emp.data);
      setMdProfile(mdProfile.data);
      setManagers(managers.data); //.map(e=>toTypeAheadModel(e))
    });
  }, []);

  useEffect(() => {
    // console.log('setup data ', meetingType, deptRelation, prop);
    if (meetingType && deptRelation) {
      if (prop && prop.view && prop.view !== 'new' && prop.id) {
        // console.log('Edit request ', prop.id);
        getById(+prop.id).then((value) => {
          // if (value.data.fileName) setFileName(value.data.fileName);
          setRequestApproval(value.data);
          if (value.data.id) {
            setValue('id', value.data.id);

            getCommandChain(value.data.id)
              .then((detail) => {
                setAllCommandChain(detail.data);

                let index = detail.data.findIndex(
                  (item) => item.empID === value.data.approver
                );

                setIndexOfApprover(index);
              })
              .catch((err) => {
                console.log('err', err);
              });
          }

          if (value.data.meetingType) {
            setupMeeting(value.data.meetingType);
            if (value.data.agendaType) {
              setValue('agendaType', value.data.agendaType);
            }
          }

          if (value.data.subject) setValue('subject', value.data.subject);
          if (value.data.comment) setValue('comment', value.data.comment);
          if (value.data.meetingId) setValue('meetingId', value.data.meetingId);
          if (value.data.defaultApprover)
            setValue('defaultApprover', value.data.defaultApprover);
          if (value.data.meetingSchedule)
            setValue('meetingSchedule', value.data.meetingSchedule);

          if (value.data.meetingSchedule)
            setSelectedMeetingSchedule(value.data.meetingSchedule);

          if (value.data.department) {
            setupDept(value.data.department);
          }

          if (value.data.status) setValue('status', value.data.status);

          if (value.data.presentationFileName)
            setPresentationFileName(value.data.presentationFileName);
          if (value.data.memoFileName) setMemoFileName(value.data.memoFileName);
          if (value.data.powerpointFileName)
            setPowerpointFileName(value.data.powerpointFileName);

          if (value.data.relatedDepartment) {
            setValue('relatedDepartment', value.data.relatedDepartment);

            let filterDept = deptRelation.filter((item) => {
              if (item.deptID) {
                return !value.data.relatedDepartment!.includes(item.deptID);
              }
            });

            setAllDeptRelation(filterDept);
          }

          if (value.data.createBy) {
            getProfileByEmpId(value.data.createBy)
              .then((emp) => {
                setEmployeeDetail(emp.data);
              })
              .catch((err) => {
                console.log('err', err);
              });
          }

          if (value.data.createDate) {
            setRequestDate(value.data.createDate);
          }

          if (value.data.status) {
            if (sessionDetail?.deptRelation?.deptID === '100002') {
              setIsDisabled(false);
            } else {
              if (
                value.data.status === RequestApprovalEnum.PENDING ||
                value.data.status === RequestApprovalEnum.APPROVED
              ) {
                if (value.data.approver === sessionDetail?.empID) {
                  if (mdProfile.length !== 0) {
                    console.log('mdProfile', mdProfile);
                    const affi = mdProfile.filter(
                      (md) =>
                        md.meetingType === value.data.meetingType &&
                        md.affiliatedFlag
                    );
                    console.log('affi ', affi);
                    setIsAcknowledge(affi.length !== 0);
                  }
                  setIsDisabled(false);
                } else {
                  setIsDisabled(true);
                }
              } else if (
                value.data.approver === 'CS' &&
                value.data.status === RequestApprovalEnum.MDAPPROVED
              ) {
                if (sessionDetail?.deptRelation?.deptID === '100002') {
                  setIsDisabled(false);
                } else {
                  setIsDisabled(true);
                }
              } else if (value.data.status === RequestApprovalEnum.COMPLETED) {
                setIsDisabled(true);
              } else {
                setIsDisabled(false);
              }
            }
          } else {
            setIsDisabled(false);
          }
        });
      } else {
        setValue('status', RequestApprovalEnum.DRAFT);
      }
    }
  }, [meetingType, deptRelation, sessionDetail, mdProfile]);

  function getDeptIdUnitUp() {
    let deptId = employeeDetail?.deptRelation?.deptID;
    if (employeeDetail?.deptRelation?.unit != null) {
      deptId = employeeDetail?.deptRelation?.department;
    }
    return deptId;
  }

  useEffect(() => {
    if (employeeDetail && deptRelation) {
      const division = deptRelation.find(
        (item) => item.deptID === employeeDetail.deptRelation?.division
      );
      setDivision(division);

      if (!prop || !prop.view || prop.view === 'new' || !prop.id) {
        const userDeptRelation = deptRelation.find((item) => {
          let deptId = getDeptIdUnitUp();
          return item.deptID === deptId;
        });
        setSelectedDept(userDeptRelation);
      }
    }
  }, [employeeDetail, deptRelation]);

  useEffect(() => {
    if (selectedDept && prop && prop.view && ['new'].includes(prop.view)) {
      getDivisionApprover(selectedDept.deptID)
        .then((res) => res.data)
        .then((res) => setValue('defaultApprover', res.empId));
    }
  }, [selectedDept]);

  const toTypeAheadModel = (e: EmployeeProfileModel) => {
    return { label: e.nameEn, value: e.empID } as IEmployeeTypeahead;
  };

  useEffect(() => {
    if (employeeDetail && deptRelation) {
      const division = deptRelation.find(
        (item) => item.deptID === employeeDetail.deptRelation?.division
      );
      setDivision(division);
    }

    if (prop && prop.view && prop.view !== 'new' && prop.id) {
      let relatedDept = getValues('relatedDepartment');
      if (deptRelation) {
        if (Array.isArray(deptRelation) && Array.isArray(relatedDept)) {
          let data = relatedDept.map((item) => {
            return deptRelation.find((dept) => {
              return item === dept.deptID;
            })!;
          });

          if (data) {
            setSelectRelatedDept(data);
          }
        }
      }
    }
  }, [employeeDetail, deptRelation]);

  const uploadFile = (id: string) => {
    const el = document.getElementById(id);
    if (el) el.click();
  };

  const fileHandle = (e: ChangeEvent<HTMLInputElement>) => {
    const type = e.target.id;
    const f = e.target.files;
    if (!f) return;

    const fileDetail = f.item(0);

    if (!fileDetail) return;

    if (fileDetail.size / 1024 / 1024 >= 10) {
      popup(false, 'ไฟล์มีขนาดใหญ่เกิน 10 MB');
      return;
    }
    if (type === 'presentation') {
      setPresentationFile(fileDetail);
    } else if (type === 'memo') {
      setMemoFile(fileDetail);
    } else if (type === 'powerpoint') {
      setPowerpointFile(fileDetail);
    }
  };

  const onSubmit = (data: FormValue) => {
    setIsUploading(true);
    if (selectedMeeting) {
      data.meetingType = selectedMeeting.id;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    if (selectedDept) {
      data.department = selectedDept.deptID;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    if (selectedAgenda) {
      data.agendaType = selectedAgenda?.id;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    if (selectedMeetingSchedule) {
      data.meetingSchedule = selectedMeetingSchedule;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    const approver = getValues('defaultApprover');
    console.log('default approver', approver);
    if (!approver) {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    if (Array.isArray(selectRelatedDept)) {
      if (selectRelatedDept) {
        let selectedRelatedDepartment = selectRelatedDept.map((item) => {
          return item.deptID!;
        });
        if (selectedRelatedDepartment) {
          data.relatedDepartment = selectedRelatedDepartment;
        }
      }
    }

    data.status = RequestApprovalEnum.PENDING;

    if (prop && prop.view && prop.view !== 'new') {
      let action = RequestAction.SUBMIT;

      if (getValues('status') === 'PENDING' && actionStatus === 'APPROVE') {
        action = RequestAction.APPROVE;
      }

      if (actionStatus === 'REJECT') {
        action = RequestAction.REJECT;
      }

      if (getValues('status') === 'APPROVED' && actionStatus === 'MD_REJECT') {
        action = RequestAction.MD_REJECT;
      }

      if (getValues('status') === 'APPROVED' && actionStatus === 'MD_APPROVE') {
        action = RequestAction.MD_APPROVE;
      }

      if (
        (getValues('status') === 'MD_APPROVED' ||
          getValues('status') === 'MD_ACKNOWLEDGE') &&
        actionStatus === 'COMPLETE'
      ) {
        action = RequestAction.CS_SUBMIT;
      }

      if (actionStatus === 'SAVE') {
        action = RequestAction.SAVE;
      }

      updateRequestApproval(data, action)
        .then((res) => {
          if (res.status === 200) {
            if (presentationFile || memoFile || powerpointFile) {
              callUpload(res.data?.id).then(() => {
                popup(true, '').then(() => {
                  prop.callBack();
                });
              });
            } else {
              popup(true, '').then(() => {
                prop.callBack();
              });
              if (res.data.convertPPTXFailed) {
                warning(
                  'We are unable to convert the PPTX file. Please attach a PDF file to the Emeeting instead.'
                );
              }
            }
          } else {
            setIsUploading(false);
            popup(false, '');
            return;
          }
        })
        .catch((err) => {
          setIsUploading(false);
          popup(false, '');
          return;
        });
    } else {
      addRequestApproval(data, RequestAction.SUBMIT)
        .then((res) => {
          if (res.status === 200) {
            if (presentationFile || memoFile || powerpointFile) {
              callUpload(res.data?.id).then(() => {
                popup(true, '').then(() => {
                  prop.callBack();
                });
              });
            } else {
              popup(true, '').then(() => {
                prop.callBack();
              });
            }
          } else {
            setIsUploading(false);
            popup(false, '');
            return;
          }
        })
        .catch((err) => {
          setIsUploading(false);
          popup(false, '');
          return;
        });
    }
  };

  const onSaveDraft = (data: FormValue) => {
    setIsUploading(true);
    if (Array.isArray(selectRelatedDept)) {
      if (selectRelatedDept) {
        let selectedRelatedDepartment = selectRelatedDept.map((item) => {
          return item.deptID!;
        });
        if (selectedRelatedDepartment) {
          data.relatedDepartment = selectedRelatedDepartment;
        }
      }
    }

    if (selectedMeeting) {
      data.meetingType = selectedMeeting.id;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    if (selectedDept) {
      data.department = selectedDept.deptID;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    if (selectedAgenda) {
      data.agendaType = selectedAgenda?.id;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    if (selectedMeetingSchedule) {
      data.meetingSchedule = selectedMeetingSchedule;
    } else {
      setIsUploading(false);
      popup(false, Message.SAVE_FAIL_INVALID_FORM);
      return;
    }

    data.status = RequestApprovalEnum.DRAFT;

    if (prop && prop.view && prop.view !== 'new') {
      updateRequestApproval(data, RequestAction.DRAFT)
        .then((res) => {
          if (res.status === 200) {
            if (presentationFile || memoFile || powerpointFile) {
              callUpload(res.data?.id).then(() => {
                popup(true, '').then(() => {
                  prop.callBack();
                });
              });
            } else {
              popup(true, '').then(() => {
                prop.callBack();
              });
            }
          } else {
            setIsUploading(false);
            popup(false, '');
            return;
          }
        })
        .catch((err) => {
          setIsUploading(false);
          popup(false, '');
          return;
        });
    } else {
      addRequestApproval(data, RequestAction.DRAFT)
        .then((res) => {
          if (res.status === 200) {
            if (presentationFile || memoFile || powerpointFile) {
              callUpload(res.data?.id).then(() => {
                popup(true, '').then(() => {
                  prop.callBack();
                });
              });
            } else {
              popup(true, '').then(() => {
                prop.callBack();
              });
            }
          } else {
            setIsUploading(false);
            popup(false, '');
            return;
          }
        })
        .catch((err) => {
          setIsUploading(false);
          popup(false, '');
          return;
        });
    }
  };

  const callUpload = async (id: number | undefined) => {
    if (presentationFile && id) {
      const payload = new FormData();
      payload.set('file', presentationFile);
      payload.set('id', id.toString());
      payload.set('fileType', 'presentation');
      await toUpload(payload);
    }

    if (memoFile && id) {
      const payload = new FormData();
      payload.set('file', memoFile);
      payload.set('id', id.toString());
      payload.set('fileType', 'memo');
      await toUpload(payload);
    }
    console.log('Here 1', powerpointFile);
    console.log('id', id);
    if (powerpointFile && id) {
      console.log('Here 2');

      const payload = new FormData();
      payload.set('file', powerpointFile);
      payload.set('id', id.toString());
      payload.set('fileType', 'powerpoint');
      await toUpload(payload);
    }
  };

  const onSelectMeeting = (e: ChangeEvent<HTMLSelectElement>) => {
    setupMeeting(e.target.value);
  };

  const setupMeeting = (id: string) => {
    const c = meetingType?.meetingTypes?.find((v) => v.id === id);
    if (c) {
      setSelectedAgenda(null);
      setSelectedMeetingSchedule(null);
      setSelectedMeeting(c);
      findAgendaType(id);
      findMeetingSchedule(id);
    } else {
      setSelectedMeeting(null);
    }
  };

  const findAgendaType = (id: string) => {
    getAgendaType(id)
      .then((value) => {
        // console.log('Agenda Type ==> ', value.data);
        setAgendaTypeList(value.data);

        let agendaTypeData = getValues('agendaType');

        if (agendaTypeData && value.data) {
          const c = value.data?.agendaTypes?.find(
            (v) => v.id === agendaTypeData
          );
          if (c) {
            setSelectedAgenda(c);
          }
        }
      })
      .catch((err) => {
        setAgendaTypeList(null);
      });
  };

  const onSelectAgenda = (e: ChangeEvent<HTMLSelectElement>) => {
    setupAgenda(e.target.value);
  };

  const setupAgenda = (id: string) => {
    const c = agendaTypeList?.agendaTypes?.find((v) => v.id === id);
    if (c) {
      setSelectedAgenda(c);
    }
  };

  const findMeetingSchedule = (id: string) => {
    getMeetingScheduleByMeetingType(id, getValues('id'))
      .then((value) => {
        setMeetingSchedule(value.data);
      })
      .catch((err) => {
        setMeetingSchedule(null);
      });
  };

  const onSelectMeetingDate = (e: ChangeEvent<HTMLSelectElement>) => {
    setupMeetingSchedule(+e.target.value);
  };

  const setupMeetingSchedule = (id: number) => {
    const c = meetingSchedule?.find((v) => v.id === id);
    if (c) {
      setSelectedMeetingSchedule(c);
    }
  };

  const onSelectDept = (e: ChangeEvent<HTMLSelectElement>) => {
    setupDept(e.target.value);
  };

  const setupDept = (id: string) => {
    const c = deptRelation?.find((v) => v.deptID === id);
    if (c) {
      setSelectedDept(c);
    }
  };

  const downFileByName = (fileName: string | undefined, fileType: string) => {
    if (fileName) {
      getFileByName(getValues('id'), fileName, fileType).then((res) => {
        saveAs(res.data, fileName);
      });
    }
  };

  const removeFile = (fileType: string) => {
    Swal.fire({
      title: 'คุณต้องการที่จะลบไฟล์นี้ใช่หรือไม่ ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
    }).then((result) => {
      if (result.isConfirmed) {
        if (fileType === 'presentation') {
          setPresentationFile(undefined);
        } else if (fileType === 'memo') {
          setMemoFile(undefined);
        } else if (fileType === 'powerpoint') {
          setPowerpointFile(undefined);
        }
      }
    });
  };

  const removeFileByName = (fileName: string, fileType: string) => {
    Swal.fire({
      title: 'คุณต้องการที่จะลบไฟล์นี้ใช่หรือไม่ ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่ใช่',
    }).then((result) => {
      if (result.isConfirmed) {
        if (fileName) {
          deleteFileByName(getValues('id'), fileName, fileType).then(() => {
            if (fileType === 'presentation') {
              setPresentationFileName('');
            } else if (fileType === 'memo') {
              setMemoFileName('');
            } else if (fileType === 'powerpoint') {
              setPowerpointFileName('');
            }
          });
        }
      }
    });
  };

  const caseEmployeeProfile = (): Option[] => {
    const a =
      managers &&
      managers.find((emp) => emp.empID === getValues('defaultApprover'));
    if (a) return [a as Option];
    return [];
  };

  const popup = (success: boolean, msg: string) => {
    return Swal.fire({
      text: msg,
      title: success ? 'บันทึกข้อมูลเรียบร้อยแล้ว' : 'บันทึกข้อมูลไม่สำเร็จ',
      icon: success ? 'success' : 'error',
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });
  };

  const warning = (msg: string) => {
    return Swal.fire({
      text: msg,
      title: 'Warning',
      icon: 'warning',
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });
  };
  const onSelectRelatedDept = (
    e: React.SyntheticEvent,
    value: DeptRelation[],
    reason: any
  ) => {
    let selectRelatedDeptList = selectRelatedDept;

    if (reason === 'clear') {
      const list: DeptRelation[] = [];
      setSelectRelatedDept(list);
    } else if (reason === 'removeOption') {
      setSelectRelatedDept(value);
    } else {
      selectRelatedDeptList.push(value[value.length - 1]);
      setSelectRelatedDept(selectRelatedDeptList);
    }

    let filterDept = deptRelation.filter((item) => {
      return !value.includes(item);
    });

    setAllDeptRelation(filterDept);
  };

  return (
    <div className="container-fluid">
      <div
        className="row border-bottom border-warning mt-4"
        style={{ paddingBottom: '1rem' }}
      >
        <h4 className="col-auto">
          <strong>Request Form</strong>
        </h4>
        {getValues('status') && (
          <h6
            className={`${getValues('status')} col-auto col-form-label`}
            style={{ height: '50%' }}
          >
            {getValues('status')}
          </h6>
        )}
        {prop.id &&
          allCommandChain &&
          !(
            getValues('status') === 'DRAFT' ||
            getValues('status') === 'REJECTED' ||
            getValues('status') === 'MD_REJECTED'
          ) && (
            <div id="action-flow" className="line col-9">
              <ul id="line" style={{ margin: '0px', marginBottom: '0px' }}>
                {allCommandChain?.map((it, index) => {
                  return (
                    <li
                      key={`line-${it.empID}`}
                      className={`${
                        indexOfApprover > index ||
                        getValues('status') === 'APPROVED' ||
                        getValues('status') === 'COMPLETED' ||
                        getValues('status') === 'MD_APPROVED' ||
                        getValues('status') === 'MD_ACKNOWLEDGE'
                          ? 'active'
                          : ''
                      }`}
                    >
                      {it.cluster}
                      <p
                        id={`name-${it.empID}`}
                        style={{ fontSize: '16px', marginBottom: '0px' }}
                      >
                        <small>{it.nameEn ? it.nameEn : ''}</small>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
      </div>

      <div className="row mt-3">
        <h6 className="col-2-5 d-flex justify-content-end">ฝ่าย:</h6>
        <h6 className="col-3">
          {employeeDetail?.deptRelation?.deptName
            ? employeeDetail?.deptRelation?.deptName
            : '-'}
        </h6>
        <h6 className="col-2 d-flex justify-content-end">Request Date:</h6>
        <h6 className="col-4">{Moment(requestDate).format('DD MMM YYYY')}</h6>
      </div>

      <div
        className="row  border-bottom border-warning mt-2"
        style={{ paddingBottom: '1rem' }}
      >
        <h6 className="col-2-5 d-flex justify-content-end">สายงาน:</h6>
        <h6 className="col-3">
          {division?.deptName ? division?.deptName : '-'}
        </h6>
        <h6 className="col-2 d-flex justify-content-end">Requester:</h6>
        <h6 className="col-4" style={{ paddingRight: '0rem' }}>
          {employeeDetail?.nameEn ? employeeDetail?.nameEn : '-'}
        </h6>
      </div>

      <div className="row" style={{ marginTop: '2rem' }}>
        <h5>รายละเอียด</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="meeting"
            >
              Meeting <span className="text-danger">*</span>
            </label>
            <div className="col-sm-3">
              <select
                id="meeting"
                className="form-select"
                onChange={onSelectMeeting}
                value={selectedMeeting?.id}
                disabled={isDisabled}
              >
                <option value={undefined}>-- กรุณาระบุ --</option>
                {meetingType?.meetingTypes?.map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.subject}
                  </option>
                ))}
              </select>
            </div>
            <label className="col-2 d-flex justify-content-end col-form-label">
              Meeting Date <span className="text-danger">*</span>
            </label>
            <div className="col-sm-4">
              <select
                id="meetingDate"
                className="form-select"
                onChange={onSelectMeetingDate}
                value={selectedMeetingSchedule?.id}
                disabled={isDisabled}
              >
                <option value={undefined}>-- กรุณาระบุ --</option>
                {meetingSchedule?.map((value) => (
                  <option key={value.id} value={value.id}>
                    {Moment(value?.meetingDate).format('DD MMM YYYY')}{' '}
                    {Moment(value?.meetingStartTime).format('HH:mm')} -{' '}
                    {Moment(value?.meetingEndTime).format('HH:mm')}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-2-5 d-flex justify-content-end col-form-label">
              Department/Group/Division <span className="text-danger">*</span>
            </label>
            <div className="col-sm-3">
              <select
                id="department"
                className="form-select"
                onChange={onSelectDept}
                value={selectedDept?.deptID}
                disabled={isDisabled}
              >
                <option value={undefined}>-- กรุณาระบุ --</option>
                {deptRelation?.map((value) => (
                  <option key={value.deptID} value={value.deptID}>
                    {value.deptName}
                  </option>
                ))}
              </select>
            </div>
            <label
              className="col-2 d-flex justify-content-end col-form-label"
              htmlFor="meetingStartTime"
            >
              กำหนดวันหารือ ผจก
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                id="meetingStartTime"
                value={
                  selectedMeetingSchedule?.id
                    ? Moment(selectedMeetingSchedule?.mdDate).format(
                        'DD MMM YYYY'
                      ) +
                      ' ' +
                      Moment(selectedMeetingSchedule?.mdStartTime).format(
                        'HH:mm'
                      ) +
                      ' - ' +
                      Moment(selectedMeetingSchedule?.mdEndTime).format('HH:mm')
                    : ''
                }
                disabled={true}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="agendaType"
            >
              Agenda Type <span className="text-danger">*</span>
            </label>
            <div className="col-sm-3">
              <select
                id="agenda"
                className="form-select"
                onChange={onSelectAgenda}
                value={selectedAgenda?.id}
                disabled={isDisabled}
              >
                <option value={undefined}>-- กรุณาระบุ --</option>
                {agendaTypeList?.agendaTypes?.map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.descr}
                  </option>
                ))}
              </select>
            </div>
            <label
              className="col-2 d-flex justify-content-end col-form-label"
              htmlFor="meetingChairMan"
            >
              กำหนดวันหารือ ประธาน
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                id="meetingChairMan"
                value={
                  selectedMeetingSchedule?.id
                    ? Moment(selectedMeetingSchedule?.chairManDate).format(
                        'DD MMM YYYY'
                      ) +
                      ' ' +
                      Moment(selectedMeetingSchedule?.chairManStartTime).format(
                        'HH:mm'
                      ) +
                      ' - ' +
                      Moment(selectedMeetingSchedule?.chairManEndTime).format(
                        'HH:mm'
                      )
                    : ''
                }
                disabled={true}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="agenda"
            >
              Agenda <span className="text-danger">*</span>
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                id="agenda"
                {...register('subject', { required: true })}
                disabled={isDisabled}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="relatedDepartment"
            >
              Related Department
            </label>
            <div className="col-sm-3">
              <Autocomplete
                value={selectRelatedDept}
                id="relatedDepartment"
                fullWidth
                multiple
                options={allDeptRelation}
                getOptionLabel={(option: any) => option.deptName}
                onChange={onSelectRelatedDept}
                style={{ padding: '4px' }}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} size="small" variant="outlined" />
                )}
                disabled={isDisabled}
              />
            </div>
            {(getValues('status') === 'DRAFT' ||
              !getValues('status') ||
              getValues('status') === 'REJECTED' ||
              getValues('status') === 'MD_REJECTED') && (
              <label
                className="col-2 d-flex justify-content-end col-form-label"
                htmlFor="defaultApprover"
              >
                Approver<span className="text-danger">*</span>
              </label>
            )}
            {(getValues('status') === 'DRAFT' ||
              !getValues('status') ||
              getValues('status') === 'REJECTED' ||
              getValues('status') === 'MD_REJECTED') && (
              <div className="col-md-4">
                {/* <Controller
                    name='defaultApprover'
                    control={control}
                    render={() => (
                      <Typeahead
                        id="defaultApprover"
                        clearButton={true}
                        labelKey={(option: any) => `${option.nameEn}`}
                        onChange={(value: any) => {
                          if (value[0]) {
                            setValue('defaultApprover', value[0]?.empID);
                          } else {
                            setValue('defaultApprover', '');
                          }
                        }}
                        selected={caseEmployeeProfile()}
                        options={managers}
                        disabled={isDisabled}

                      />
                    )}
                  /> */}
              </div>
            )}
          </div>

          {/* <div className="row mb-3">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="attachFile"
            >
              Presentation File (PDF)
            </label>
            <div className="col-sm-8">
              <div className="row">
                <button
                  type="button"
                  className="btn btn-primary col-sm-auto"
                  onClick={(e) => uploadFile('presentation')}
                  disabled={isDisabled}
                  style={{ marginLeft: '1rem' }}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  id="presentation"
                  hidden={true}
                  onChange={fileHandle}
                  accept={'.pdf'}
                  className="col-sm-auto"
                />
                {presentationFile ? (
                  <div className="col-auto">
                    <label
                      className="is-link text-info col-form-label"
                      style={{ marginLeft: '1rem' }}
                    >
                      {presentationFile?.name}
                      <span
                        style={{ marginLeft: '0.5rem' }}
                        onClick={(e) => removeFile('presentation')}
                      >
                        {!isDisabled && (
                          <IconButton className="text-danger" size="small">
                            <Clear />
                          </IconButton>
                        )}
                      </span>
                    </label>
                  </div>
                ) : (
                  <div className="col-auto">
                    <label
                      className=" is-link text-info col-form-label"
                      style={{ marginLeft: '1rem' }}
                      onClick={(e) =>
                        downFileByName(presentationFileName, 'presentation')
                      }
                    >
                      {presentationFileName ? presentationFileName : ''}
                    </label>
                    <span className="col-auto">
                      {presentationFileName && (
                        <span style={{ marginLeft: '0.5rem' }}>
                          {!isDisabled && (
                            <IconButton
                              onClick={(e) =>
                                removeFileByName(
                                  presentationFileName,
                                  'presentation'
                                )
                              }
                              className="text-danger"
                              size="small"
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div> */}

          <div className="row mb-3">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="attachFile"
            >
              Powerpoint File (PPTX)
            </label>
            <div className="col-sm-8">
              <div className="row">
                <button
                  type="button"
                  className="btn btn-primary col-sm-auto"
                  onClick={(e) => uploadFile('powerpoint')}
                  disabled={isDisabled}
                  style={{ marginLeft: '1rem' }}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  id="powerpoint"
                  hidden={true}
                  onChange={fileHandle}
                  accept={'.pptx'}
                  className="col-sm-auto"
                />
                {powerpointFile ? (
                  <div className="col-sm-auto">
                    <label
                      className="is-link text-info col-form-label"
                      style={{ marginLeft: '1rem' }}
                    >
                      {powerpointFile?.name}
                      <span
                        style={{ marginLeft: '0.5rem' }}
                        onClick={(e) => removeFile('powerpoint')}
                      >
                        {!isDisabled && (
                          <IconButton className="text-danger" size="small">
                            <Clear />
                          </IconButton>
                        )}
                      </span>
                    </label>
                  </div>
                ) : (
                  <div className="col-auto">
                    <label
                      className=" is-link text-info col-form-label"
                      style={{ marginLeft: '1rem' }}
                      onClick={(e) =>
                        downFileByName(powerpointFileName, 'powerpoint')
                      }
                    >
                      {powerpointFileName ? powerpointFileName : ''}
                    </label>
                    <span className="col-auto">
                      {powerpointFileName && (
                        <span style={{ marginLeft: '0.5rem' }}>
                          {!isDisabled && (
                            <IconButton
                              onClick={(e) =>
                                removeFileByName(
                                  powerpointFileName,
                                  'powerpoint'
                                )
                              }
                              className="text-danger"
                              size="small"
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="attachFile"
            >
              Memo File (PDF) (ถ้ามี)
            </label>
            <div className="col-sm-8">
              <div className="row">
                <button
                  type="button"
                  className="btn btn-primary col-sm-auto"
                  onClick={(e) => uploadFile('memo')}
                  disabled={isDisabled}
                  style={{ marginLeft: '1rem' }}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  id="memo"
                  hidden={true}
                  onChange={fileHandle}
                  accept={'.pdf'}
                  className="col-sm-auto"
                />
                {memoFile ? (
                  <div className="col-auto">
                    <label
                      className="is-link text-info col-form-label"
                      style={{ marginLeft: '1rem' }}
                    >
                      {memoFile?.name}
                      <span
                        style={{ marginLeft: '0.5rem' }}
                        onClick={(e) => removeFile('memo')}
                      >
                        {!isDisabled && (
                          <IconButton className="text-danger" size="small">
                            <Clear />
                          </IconButton>
                        )}
                      </span>
                    </label>
                  </div>
                ) : (
                  <div className="col-auto">
                    <label
                      className=" is-link text-info col-form-label"
                      style={{ marginLeft: '1rem' }}
                      onClick={(e) => downFileByName(memoFileName, 'memo')}
                    >
                      {memoFileName ? memoFileName : ''}
                    </label>
                    <span className="col-auto">
                      {memoFileName && (
                        <span style={{ marginLeft: '0.5rem' }}>
                          {!isDisabled && (
                            <IconButton
                              onClick={(e) =>
                                removeFileByName(memoFileName, 'memo')
                              }
                              className="text-danger"
                              size="small"
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-3 pb-4 border-bottom border-warning">
            <label
              className="col-2-5 d-flex justify-content-end col-form-label"
              htmlFor="Comment"
            >
              Comment
            </label>
            <div className="col-sm-9">
              <textarea
                className="form-control"
                id="commet"
                rows={3}
                {...register('comment', { required: false })}
                disabled={isDisabled}
              ></textarea>
            </div>
          </div>

          <div className="mb-3">
            <h6>ประวัตืการดำเนินงาน</h6>
            <div className="row mb-3">
              <table className="table">
                <thead
                  className="table-header"
                  style={{ borderBottom: '3px solid #5e5a5a' }}
                >
                  <tr>
                    <th></th>
                    <th>ACTION BY</th>
                    <th>POSITION</th>
                    <th>ACTION</th>
                    <th>DATE</th>
                    <th>COMMENT</th>
                  </tr>
                </thead>
                {requestApproval?.transactionLogMappers?.length === 0 ||
                !requestApproval ? (
                  <tbody>
                    <tr>
                      <td colSpan={6} className="text-center">
                        <h5>
                          <b>ไม่พบข้อมูล</b>
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {requestApproval?.transactionLogMappers?.map(
                      (it, index) => {
                        return (
                          <tr key={it.id}>
                            <td>{index + 1}</td>
                            <td>{it.actor ? it.actor.nameEn : '-'}</td>
                            <td>-</td>
                            <td>{it.action}</td>
                            <td>
                              {it?.createDate
                                ? Moment(it?.createDate).format(
                                    'DD MMM YYYY HH:mm'
                                  )
                                : '-'}
                            </td>
                            <td>{it.comment ? it.comment : '-'}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <div className="row d-flex justify-content-end mb-3 mt-4">
            <div className="col-auto  ">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={prop.callBack}
              >
                <strong>BACK</strong>
              </button>
            </div>
            {(getValues('status') === 'DRAFT' ||
              !getValues('status') ||
              getValues('status') === 'REJECTED' ||
              getValues('status') === 'MD_REJECTED') && (
              <div className="col-auto  ">
                <button
                  // className="btn btn-outline-primary"
                  type="button"
                  onClick={handleSubmit(onSaveDraft)}
                  className={
                    (getValues('status') === 'DRAFT' || !getValues('status')) &&
                    !isUploading &&
                    !isDisabled
                      ? 'btn btn-outline-primary'
                      : 'btn btn-secondary'
                  }
                  disabled={
                    (getValues('status') === 'DRAFT' || !getValues('status')) &&
                    !isUploading &&
                    !isDisabled
                      ? false
                      : true
                  }
                >
                  <strong>SAVE AS DRAFT</strong>
                </button>
              </div>
            )}
            {sessionDetail?.deptRelation?.deptID === '100002' && (
              <div className="col-auto  ">
                <button
                  type="submit"
                  onClick={(e) => {
                    setActionStatus('SAVE');
                  }}
                  className={
                    isUploading || isDisabled
                      ? 'btn btn-secondary'
                      : 'btn btn-primary'
                  }
                  disabled={isUploading || isDisabled ? true : false}
                >
                  <strong>SAVE</strong>
                </button>
              </div>
            )}
            {(getValues('status') === 'DRAFT' ||
              !getValues('status') ||
              getValues('status') === 'REJECTED' ||
              getValues('status') === 'MD_REJECTED') && (
              <div className="col-auto  ">
                <button
                  type="submit"
                  onClick={(e) => {
                    setActionStatus('SUBMIT');
                  }}
                  className={
                    isUploading || isDisabled
                      ? 'btn btn-secondary'
                      : 'btn btn-primary'
                  }
                  disabled={isUploading || isDisabled ? true : false}
                >
                  <strong>SUBMIT</strong>
                </button>
              </div>
            )}
            {getValues('status') === 'PENDING' && (
              <div className="col-auto  ">
                <button
                  type="submit"
                  onClick={(e) => {
                    setActionStatus('REJECT');
                  }}
                  className={
                    isUploading || isDisabled
                      ? 'btn btn-secondary'
                      : 'btn btn-danger'
                  }
                  disabled={isUploading || isDisabled ? true : false}
                >
                  <strong>REJECT</strong>
                </button>
              </div>
            )}
            {getValues('status') === 'PENDING' && (
              <div className="col-auto  ">
                <button
                  type="submit"
                  onClick={(e) => {
                    setActionStatus('APPROVE');
                  }}
                  className={
                    isUploading || isDisabled
                      ? 'btn btn-secondary'
                      : 'btn btn-success'
                  }
                  disabled={isUploading || isDisabled ? true : false}
                >
                  <strong>APPROVE</strong>
                </button>
              </div>
            )}
            {getValues('status') === 'APPROVED' && !isAcknowledge && (
              <div className="col-auto  ">
                <button
                  type="submit"
                  onClick={(e) => {
                    setActionStatus('MD_REJECT');
                  }}
                  className={
                    isUploading || isDisabled
                      ? 'btn btn-secondary'
                      : 'btn btn-danger'
                  }
                  disabled={isUploading || isDisabled ? true : false}
                >
                  <strong>REJECT</strong>
                </button>
              </div>
            )}
            {getValues('status') === 'APPROVED' && (
              <div className="col-auto  ">
                <button
                  type="submit"
                  onClick={(e) => {
                    setActionStatus('MD_APPROVE');
                  }}
                  className={
                    isUploading || isDisabled
                      ? 'btn btn-secondary'
                      : 'btn btn-success'
                  }
                  disabled={isUploading || isDisabled ? true : false}
                >
                  <strong>{isAcknowledge ? 'ACKNOWLEDGE' : 'APPROVE'}</strong>
                </button>
              </div>
            )}
            {(getValues('status') === 'MD_APPROVED' ||
              getValues('status') === 'MD_ACKNOWLEDGE') && (
              <div className="col-auto  ">
                <button
                  type="submit"
                  onClick={(e) => {
                    setActionStatus('COMPLETE');
                  }}
                  className={
                    isUploading || isDisabled
                      ? 'btn btn-secondary'
                      : 'btn btn-success'
                  }
                  disabled={isUploading || isDisabled ? true : false}
                >
                  <strong>COMPLETE</strong>
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

type Prop = {
  view: string | undefined;
  id: string | undefined;
  callBack: () => void;
};

type FormValue = {
  id: number;
  meetingId?: number;
  meetingSchedule?: MeetingSchedule;
  meetingType?: string;
  subject: string;
  meetingDate: Date;
  meetingStartTime: Date;
  meetingEndTime: Date;
  agendaId: number;
  comment: string;
  department?: string;
  agendaType?: string;
  status?: RequestApprovalEnum;
  relatedDepartment?: Array<string>;
  defaultApprover?: string;
};

type attachmentFile = {
  file?: string;
};

export default RequestForm;
