import { useEffect, useState } from 'react';
import MDSubCompanyList from '../components/admin/MeetingMDList';
import { useHistory } from 'react-router-dom';

const AdminPendingForApproval = (props: Props) => {
  const [backupPage, setBackupPage] = useState(1);

  return (
    <>
      <MDSubCompanyList page={backupPage} />
    </>
  );
};

type Props = {
  admin: boolean;
};

export default AdminPendingForApproval;
