import { ReactElement } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loading = ({ isLoading, isInner }: Props): ReactElement => {
  return (
    <>
      <ClipLoader
        size={50}
        color={'rgb(250, 178, 50)'}
        loading={isLoading}
        speedMultiplier={1.5}
        style={{
          'position': `${isInner ? 'absolute' : 'fixed'}`,
          'top': '40%;',
          'left': '47.5%',
          'transform': 'translate(-50%, -50%)',
          'zIndex': '1000'
        }}
      />
    </>
  );
};

type Props = {
  isLoading: boolean;
  isInner?: boolean;
};

export default Loading;
