import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';

const getUserRoles = (): Promise<AxiosResponse<string[]>> => {
  return DIGITAL_FLOW_API.get<string[]>(`/role`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

export { getUserRoles };
