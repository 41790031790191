import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';
export interface IWorkPosition{
    empId: string
}
const getDivisionApprover = (deptId: string | undefined): Promise<AxiosResponse<IWorkPosition>> => {
    return DIGITAL_FLOW_API.get<IWorkPosition>(`/work-position/approver/${deptId}`, {
        headers: {
            Authorization: `Bearer ${authen_token}`,
        }
    });
};

export { getDivisionApprover };
