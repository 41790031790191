import React, { useEffect, useState } from 'react';
import MeetingScheduleList from '../components/admin/MeetingScheduleList';
import { useHistory } from 'react-router-dom';

const AdminMeetingSchedule = (props: Props) => {
  const [backupPage, setBackupPage] = useState(1);

  return (
    <>
      <MeetingScheduleList page={backupPage} />
    </>
  );
};

type Props = {
  admin: boolean;
};

export default AdminMeetingSchedule;
