import Swal from 'sweetalert2';

const confirmPopup = (title: string, msg: string) => {
    return Swal.fire({
        title: title,
        text: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#dc3545',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
    }).then((result) => result);
};

export default confirmPopup;