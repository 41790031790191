import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';
import { MeetingType } from '../models/MeetingType';
import { AgendaType } from '../models/AgendaType';
import { RequestApproval } from '../models/RequestApproval';
import { Page } from '../models/Page';
import { RequestApprovalEnum } from '../models/RequestApprovalEnum';
import { pickBy } from '../utils/object.utils';
import { RequestAction } from '../models/RequestAction';
import { RequestApprovalMapper } from '../models/RequestApprovalMapper';
import { EmployeeProfileModel } from '../models/EmployeeProfileModel';

const getAll = (
  page: number,
  size: number,
  keyword: string,
  status: RequestApprovalEnum | ''
): Promise<AxiosResponse<Page<RequestApproval>>> => {
  return DIGITAL_FLOW_API.get<Page<RequestApproval>>(`/request-approval`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { page, size, keyword, status },
  });
};

const getById = (id: number): Promise<AxiosResponse<RequestApprovalMapper>> => {
  return DIGITAL_FLOW_API.get<RequestApprovalMapper>(
    `/request-approval/${id}`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
    }
  );
};

const getMeetingType = (): Promise<AxiosResponse<MeetingType>> => {
  return DIGITAL_FLOW_API.get<MeetingType>(`/request-approval/meeting-list`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

const getAgendaType = (type?: string): Promise<AxiosResponse<AgendaType>> => {
  return DIGITAL_FLOW_API.get<AgendaType>(`/request-approval/agenda-list`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: {
      agendaType: type,
    },
  });
};

const updateRequestApproval = (
  body: RequestApproval,
  action: RequestAction
) => {
  return DIGITAL_FLOW_API.put<RequestApproval>(`/request-approval`, body, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: {
      action,
    },
  });
};

const addRequestApproval = (body: RequestApproval, action: RequestAction) => {
  return DIGITAL_FLOW_API.post<RequestApproval>(`/request-approval`, body, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: {
      action,
    },
  });
};

const toUpload = (payload: FormData) => {
  return DIGITAL_FLOW_API.post(`/request-approval/upload-attachment`, payload, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

// const getAllFileName = (id: number): Promise<AxiosResponse<string[]>> => {
//   return DIGITAL_FLOW_API.get<string[]>(
//     `/request-approval/get-all-files/${id}`,
//     {
//       headers: {
//         Authorization: `Bearer ${authen_token}`,
//       },
//     }
//   );
// };

const deleteFileByName = (id: number, fileName: string, fileType: string) => {
  return DIGITAL_FLOW_API.delete<any>(`/request-approval/delete-file-by-name`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { id, fileName, fileType },
  });
};

const getFileByName = (id: number, fileName: string, fileType: string) => {
  return DIGITAL_FLOW_API.get<any>(`/request-approval/get-file-by-name`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { id, fileName, fileType },
    responseType: 'blob',
  });
};

const getPendingApproveList = (
  page = 0,
  size: number,
  start?: Date | null,
  end?: Date | null,
  agendaType?: string | null
): Promise<AxiosResponse<Page<RequestApproval>>> => {
  const params = pickBy(
    {
      page,
      size,
      start,
      end,
      agendaType,
    },
    (v: any) => v !== undefined
  );
  return DIGITAL_FLOW_API.get<Page<RequestApproval>>(
    `/request-approval/pending-approve`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
      params: { ...params },
    }
  );
};

const getCommandChain = (
  id: number
): Promise<AxiosResponse<EmployeeProfileModel[]>> => {
  return DIGITAL_FLOW_API.get<EmployeeProfileModel[]>(
    `/request-approval/find-all-command-chain/${id}`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
    }
  );
};

const deleteRequestApproval = (id: number) => {
  return DIGITAL_FLOW_API.delete<any>(`/request-approval`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { id },
  });
};

export {
  getAll,
  getMeetingType,
  getAgendaType,
  updateRequestApproval,
  addRequestApproval,
  toUpload,
  getById,
  getPendingApproveList,
  // getAllFileName,
  deleteFileByName,
  getFileByName,
  getCommandChain,
  deleteRequestApproval,
};
