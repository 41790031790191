import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';
import { MeetingSchedule } from '../models/MeetingSchedule';
import { Page } from '../models/Page';

const getAllMeetingSchedule = (): Promise<AxiosResponse<MeetingSchedule[]>> => {
  return DIGITAL_FLOW_API.get<MeetingSchedule[]>(`/meeting-schedule`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

const getMeetingScheduleByMeetingType = (
  meetingType: string,
  requestId: number
): Promise<AxiosResponse<MeetingSchedule[]>> => {
  return DIGITAL_FLOW_API.get<MeetingSchedule[]>(
    `/meeting-schedule/find-by-meeting-type`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
      params: { meetingType, requestId },
    }
  );
};

const getMeetingSchedules = (
  page: number,
  size: number
): Promise<AxiosResponse<Page<MeetingSchedule>>> => {
  return DIGITAL_FLOW_API.get<Page<MeetingSchedule>>(`/meeting-schedule/list`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { page, size },
  });
};

const addMeetingSchedule = (body: MeetingSchedule) => {
  return DIGITAL_FLOW_API.post<MeetingSchedule>(`/meeting-schedule/add`, body, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

const updateMeetingSchedule = (id: number, body: MeetingSchedule) => {
  return DIGITAL_FLOW_API.put<MeetingSchedule>(
    `/meeting-schedule/${id}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
    }
  );
};

const deleteMeetingSchedule = (id: number) => {
  return DIGITAL_FLOW_API.delete<any>(`/meeting-schedule/${id}`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

export {
  getAllMeetingSchedule,
  getMeetingScheduleByMeetingType,
  getMeetingSchedules,
  addMeetingSchedule,
  updateMeetingSchedule,
  deleteMeetingSchedule,
};
