export type RequestApprovalEnum = 'DRAFT' | 'PENDING' | 'REJECTED' | 'APPROVED' | 'MD_REJECTED' | 'MD_APPROVED' | 'COMPLETED' | 'MD_ACKNOWLEDGE';
export const RequestApprovalEnum = {
  DRAFT: 'DRAFT' as RequestApprovalEnum,
  PENDING: 'PENDING' as RequestApprovalEnum,
  REJECTED: 'REJECTED' as RequestApprovalEnum,
  APPROVED: 'APPROVED' as RequestApprovalEnum,
  MDREJECTED: 'MD_REJECTED' as RequestApprovalEnum,
  MDAPPROVED: 'MD_APPROVED' as RequestApprovalEnum,
  MDACKNOWLEDGE: 'MD_ACKNOWLEDGE' as RequestApprovalEnum,
  COMPLETED: 'COMPLETED' as RequestApprovalEnum
};
