import { useEffect, useState } from 'react';
import { getAgenda, getAttachment, getSubject } from '../services/MeetingAPI';
import { MeetingAgendaModel } from '../models/MeetingAgendaModel';
import { useHistory, useLocation } from 'react-router-dom';
import { MeetingAgendaDetailModel } from '../models/MeetingAgendaDetailModel';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import SetLogo from '../images/logo/logo-set.svg';
import TsdLogo from '../images/logo/logo-tsd.svg';
import TchLogo from '../images/logo/logo-tch.svg';
import TfexLogo from '../images/logo/logo-tfex.svg';
import SvhLogo from '../images/logo/logo-svh.svg';
import TnvdrLogo from '../images/logo/logo-tnvdr.svg';
import LiveLogo from '../images/logo/logo-live.svg';
import FinnetLogo from '../images/logo/logo-finnet.svg';
import TdxLogo from '../images/logo/logo-tdx.svg';
import DapLogo from '../images/logo/logo-dap.svg';
import SettradeLogo from '../images/logo/logo-settrade.svg';
import { MeetingSubjectDetailModel } from '../models/MeetingSubjectDetailModel';

export const MouseHover = styled.div`
  cursor: pointer;

  :hover {
    opacity: 50%;
  }
`;

const AdminAgenda = (props: Props) => {
  const history = useHistory();
  const [agenda, setAgenda] = useState<MeetingAgendaModel | null>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [groupingData, setGroupingData] = useState<any>([]);
  const uniqueAgendaTypeIDs = new Set<number>();
  const [uniqueItems, setUniqueItems] = useState<MeetingAgendaDetailModel[]>([]);

  const [meetingType, setMeetingType] = useState<string | null>();
  const [executive, setExecutive] = useState<string | null>();


  const [subjects, setSubjects] = useState<MeetingSubjectDetailModel[]>([]);
  const [selectedSubject, setSelectedSubject] =
    useState<MeetingSubjectDetailModel | null>();

  useEffect(() => {
    const meetingType = queryParams.get('meetingType');
    const executive = queryParams.get('executive');

    setupSubject(meetingType);

    setMeetingType(meetingType);
    setExecutive(executive);

    console.log('param >>', meetingType, executive);

  }, [queryParams.get('meetingType'), queryParams.get('executive')]);


  useEffect(() => {
    if (selectedSubject?.id && executive) {
      history.replace(`/admin/agenda?meetingType=${meetingType}&executive=${executive}`);

      const isExecutive = executive === 'true';
      getAgenda(selectedSubject?.id, isExecutive).then((data) => {
        setAgenda(data.data);
        if (data.data) {
          let groupedItems = data.data.agenda.reduce((groups: any, item) => {
            const agendaTypeID = item.agendaTypeID;

            if (!groups[agendaTypeID]) {
              groups[agendaTypeID] = [];
            }

            groups[agendaTypeID].push(item);

            return groups;
          }, {});
          setGroupingData(groupedItems);

          const uniqueItems = data.data.agenda.filter((item) => {
            if (!uniqueAgendaTypeIDs.has(item.agendaTypeID)) {
              uniqueAgendaTypeIDs.add(item.agendaTypeID);
              return true;
            }
            return false;
          });
          setUniqueItems(uniqueItems);
        }
      });
    }
  }, [selectedSubject]);

  const setupSubject = (id: string | undefined | null) => {
    console.log('id ===> ', id);
    if (!id) return;

    getSubject(id)
      .then((value) => {
        const latestSubject = value.data
          ?.filter((item) => item.status && item.rawDate)
          .filter((item) => item.status.toUpperCase() === 'PUBLISH')
          .sort((a, b) => {
            const dateA = new Date(a.rawDate).getTime();
            const dateB = new Date(b.rawDate).getTime();
            return dateB - dateA;
          })[0];

        console.log('latestSubject >', latestSubject, subjects);

        setSelectedSubject(latestSubject);
        return latestSubject?.id;
      })
      .catch((err) => {
        setSubjects([]);
      });
  };

  const handleOpenDocument = (agendaDetail: MeetingAgendaDetailModel) => {
    const attach = getAttachFile(agendaDetail) !== null ? getAttachFile(agendaDetail) : null;
    if (attach) {
      let meetingType = agenda?.meetingType ? agenda?.meetingType : '';
      getAttachment(
        agendaDetail.agendaID.toString(),
        meetingType,
        attach
      )
        .then((res) => {
          if (res.status === 200) {
            saveAs(res.data, attach);
          } else {
            alert('เกิดข้อผิดพลาดขณะดาวน์โหลดเอกสาร กรุณาลองใหม่อีกครั้ง', true);
          }
        })
        .catch((err) => {
          alert('เกิดข้อผิดพลาดขณะดาวน์โหลดเอกสาร กรุณาลองใหม่อีกครั้ง', true);
        });
    }
  };

  const alert = (msg: string, isError: Boolean) => {
    return Swal.fire({
      text: msg,
      title: isError ? 'ข้อผิดพลาด' : 'คำเตือน',
      icon: isError ? 'error' : 'warning',
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });
  };

  const getAttachFile = (value: MeetingAgendaDetailModel) => {
    const agendaTypes = ['หนังสือเชิญประชุม', 'รับรองรายงานการประชุม'];
    if (agendaTypes.includes(value?.agendaTypeDescr)) {
      return value.attach1 && value.attach1 !== '' ? value.attach1 : null;
    } else {
      return value.attach2 && value.attach2 !== '' ? value.attach2 : value.attach1 && value.attach1 !== '' ? value.attach1 : null;
    }
  };

  const getLogo = (meetingType: string | undefined): string | undefined => {
    switch (meetingType) {
      case 'SET':
        return `${SetLogo}`;
      case 'TSD':
        return `${TsdLogo}`;
      case 'TCH':
        return `${TchLogo}`;
      case 'TFEX':
        return `${TfexLogo}`;
      case 'TNVDR':
        return `${TnvdrLogo}`;
      case 'SVH':
        return `${SvhLogo}`;
      case 'STT':
        return `${SettradeLogo}`;
      case 'LIVE':
        return `${LiveLogo}`;
      case 'FINNET':
        return `${FinnetLogo}`;
      case 'TDX':
        return `${TdxLogo}`;
      case 'DAP':
        return `${DapLogo}`;
      default:
        return undefined;
    }
  };

  const replaceSpace = (lb: string) => {
    return lb.replace(/ /g, '&nbsp;');
  };

  const specialMeetingTypes = ['TDX', 'DAP', 'FINNET', 'LIVE'];
  const hasSpecialMeetingType = specialMeetingTypes.includes(agenda?.meetingType ? agenda?.meetingType : '');
  const navClassName = 'row align-items-center text-center';
  const headerClass = hasSpecialMeetingType ? `${navClassName} nav-border-header` : `${navClassName} nav-header`;

  return (
    <>
      <div className="container">
        <div className={headerClass}>
          <div className="my-3">
            {
              getLogo(agenda?.meetingType) !== undefined ?
                <div className="row my-1 justify-content-center">
                  <div className='col-md-auto'>
                    <img src={getLogo(agenda?.meetingType)} style={{ maxWidth: '9rem', maxHeight: 'fit-content' }}
                      alt={agenda?.meetingType} />
                  </div>
                </div> : <></>
            }
            <div className="row py-1">
              <h4 className="col-12 d-flex justify-content-center mb-0">
                {agenda?.meeting[0]?.subject}
              </h4>
            </div>
            <div className="row py-1 justify-content-center">
              <h5 className='mb-0'>
                {agenda?.meeting[0]?.meetingNo} {agenda?.meeting[0]?.date}
              </h5>
            </div>
            <div className="row py-1 justify-content-center">
              <div className='col'>
                <h5 className="col-md-auto mb-0 align-self-center">
                  {agenda?.meeting[0]?.venue}
                  &nbsp;&nbsp;{agenda?.meeting[0]?.from ? `เวลา ${agenda?.meeting[0]?.from}` : ''}
                  &nbsp;{agenda?.meeting[0]?.to ? ` ถึง ${agenda?.meeting[0]?.to} น.` : ''}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <table className="table">
            {uniqueItems?.map((value, index) => (
              <>
                <tbody style={{ borderTop: 'none' }}>
                  <tr style={{
                    backgroundColor: 'antiquewhite'
                  }}>
                    <td colSpan={3}>
                      <div style={{ marginLeft: '2%' }}>
                        <h5 className='mb-0'>{value.agendaTypeDescr}</h5>
                      </div>
                    </td>
                  </tr>
                  {groupingData[value.agendaTypeID].map((item: any) => (
                    <>
                      <tr className='align-middle'>
                        <td width={'4%'}></td>
                        <td>
                          <label key={item.agendaID} className="text-black py-2 pb-0"
                            dangerouslySetInnerHTML={{ __html: replaceSpace(item.subject) }}>
                            {/* {item.subject} */}
                          </label>
                        </td>
                        <td className='text-end' width={'10%'}>
                          {getAttachFile(item) !== null ?
                            <>
                              <MouseHover>
                                <button style={{ backgroundColor: 'orange' }}
                                  className='btn btn-warning text-white text-bold'
                                  onClick={() => handleOpenDocument(item)}>
                                  เปิด
                                </button>
                              </MouseHover>
                            </> : <></>}
                          {item.docInWaiting && getAttachFile(item) === null ?
                            <div className='text-secondary'>นำส่งภายหลัง</div> : <></>}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </>
            ))}
          </table>
        </div >
      </div >
    </>
  );
};

type Props = {
  admin: boolean;
  boardAdmin?: boolean;
};

export default AdminAgenda;
