import { ChangeEvent, useEffect, useState } from 'react';
import { transformUser } from '../services/TransformAPI';
import {
  getAdminMeeting,
  getAttendee,
  getSubject,
} from '../services/MeetingAPI';
import { MeetingSubjectDetailModel } from '../models/MeetingSubjectDetailModel';
import { MeetingAttendeeDetailModel } from '../models/MeetingAttendeeDetailModel';
import { MeetingAdminDetailModel } from '../models/MeetingAdminDetailModel';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const AdminBoardMeeting = (props: Props) => {
  const [meetingType, setMeetingType] = useState<MeetingAdminDetailModel[]>();
  const [selectedMeeting, setSelectedMeeting] =
    useState<MeetingAdminDetailModel | null>();
  const [subject, setSubject] = useState<MeetingSubjectDetailModel[]>();
  const [selectedSubject, setSelectedSubject] =
    useState<MeetingSubjectDetailModel | null>();
  const [attendee, setAttendee] = useState<MeetingAttendeeDetailModel[]>();
  const [selectedAttendee, setSelectedAttendee] =
    useState<MeetingAttendeeDetailModel | null>();
  const [isExecutive, setExecutive] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    Promise.all([getAdminMeeting()]).then(([meetingType]) => {
      console.log('meetingType ===> ', meetingType);

      setMeetingType(meetingType.data);
    });
  }, []);

  const onSelectMeeting = (e: ChangeEvent<HTMLSelectElement>) => {
    let data = meetingType?.find((item) => item.id == e.target.value);
    if (data) {
      setSelectedMeeting(data);
    }
  };

  const setupAttendee = (id: string) => {
    getAttendee(id)
      .then((value) => {
        let data = value.data;
        data = data.filter(
          (thing, index, self) =>
            self.findIndex(
              (t) => t.email === thing.email && t.name === thing.name
            ) === index
        );

        setAttendee(data);
      })
      .catch((err) => {
        setAttendee([]);
      });
  };

  const onSelectSubject = (e: ChangeEvent<HTMLSelectElement>) => {
    let data = subject?.find((item) => item.id == Number(e.target.value));
    if (data) {
      setSelectedSubject(data);
    }
  };

  const onSelectAttendee = (e: ChangeEvent<HTMLSelectElement>) => {
    let data = attendee?.find((item) => item.email == e.target.value);
    if (data) {
      setSelectedAttendee(data);
    }
  };

  const login = () => {
    if (selectedMeeting == null) {
      return alert('กรุณากรอกข้อมูลให้ครบถ้วน', false);
    }

    window.open(
      `${window.location.origin}/admin/agenda?meetingType=${selectedMeeting.id}&executive=${isExecutive}`,
      '_blank'
    );
  };

  const alert = (msg: string, isError: Boolean) => {
    return Swal.fire({
      text: msg,
      title: isError ? 'ข้อผิดพลาด' : 'คำเตือน',
      icon: isError ? 'error' : 'warning',
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });
  };

  const handleExMeetingFlagChange = (e: boolean) => {
    console.log('onExclusiveMeeting >', e);
    setExecutive(e);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center" style={{ marginTop: '10%' }}>
          <label className="title col-4 col-md-2 col-lg-2 text-end">
            Meeting <span className="text-danger">*</span>
          </label>
          <div className="col-8 col-md-4 col-lg-4">
            <select
              id="meeting"
              className="form-select"
              onChange={onSelectMeeting}
              value={selectedMeeting?.id}
            >
              <option value={undefined}>-- กรุณาระบุ --</option>
              {meetingType?.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.type}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="row d-flex justify-content-center mt-3">
          <label className="title col-4 col-md-2 col-lg-2 text-end">
            Subject <span className="text-danger">*</span>
          </label>
          <div className="col-8 col-md-4 col-lg-4">
            <select
              id="meeting"
              className="form-select"
              onChange={onSelectSubject}
              value={selectedSubject?.id}
            >
              <option value={undefined}>-- กรุณาระบุ --</option>
              {subject?.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.subject} {value.meetingNo}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className="row d-flex justify-content-center mt-3">
          <label className="title col-4 col-md-2 col-lg-2 text-end"></label>
          <div className="col-8 col-md-4 col-lg-4 d-flex align-items-center">
            <input type='checkbox' id="exMeeting" name="exMeeting" className="form-check-input"
              onChange={(e) => handleExMeetingFlagChange(e.target.checked)}
            />
            <label className="title col-md-auto px-3" htmlFor="exMeeting">
              Executive
            </label>
          </div>

        </div>
        <div className="row d-flex justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-warning col-sm-auto"
            onClick={(e) => login()}
          >
            <strong>Login</strong>
          </button>
        </div>
      </div>
    </>
  );
};

type Props = {
  admin: boolean;
  boardAdmin?: boolean;
};

export default AdminBoardMeeting;
