import React, { useEffect, useState } from 'react';
import {
  deleteRequestApproval,
  getAgendaType,
  getAll,
} from '../services/RequestApprovalApi';
import Moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Edit, Search, Delete } from '@material-ui/icons';
import { RequestApproval } from '../models/RequestApproval';
import Pagination from '@material-ui/lab/Pagination';
import { RequestApprovalEnum } from '../models/RequestApprovalEnum';
import { useForm } from 'react-hook-form';
import { MeetingSchedule } from '../models/MeetingSchedule';
import { getSession } from '../services/EmployeeAPI';
import { EmployeeProfileModel } from '../models/EmployeeProfileModel';
import Swal from 'sweetalert2';
import confirmPopup from '../shared/confirmPopup';

const RequestTable = (prop: Prop) => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(prop.page);
  const [requestApprovalList, setRequestApprovalList] =
    useState<RequestApproval[]>();
  const [criteria, setCriteria] = useState<FormValue>({
    search: prop.search,
    status: prop.status,
  });
  const [sessionDetail, setSessionDetail] = useState<EmployeeProfileModel>();

  const { register, handleSubmit, watch } = useForm<FormValue>();
  const watchStatus = watch(['status']);

  useEffect(() => {
    Promise.all([getSession()]).then(([emp]) => {
      setSessionDetail(emp.data);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [currentPage, criteria]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'status') {
        setCriteria(value as FormValue);
      }
    });
    return () => subscription.unsubscribe();
  }, [watchStatus]);

  const loadData = () => {
    getAll(currentPage - 1, pageSize, criteria.search, criteria.status)
      .then((value) => {
        console.log('value', value.data);
        setPage(value.data.totalPages || 1);
        setRequestApprovalList(value.data.content);
      })
      .catch((err) => {
        setPage(1);
        setRequestApprovalList([]);
        console.log(err);
      });
  };

  const onPage = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const onSubmit = (data: FormValue) => {
    setCriteria(data);
  };

  const onStatusChange = (value: RequestApprovalEnum | '') => {
    console.log('tom ==> ', value);
    console.log('tom ==> ', criteria);
  };

  const toDateTimeStr = (ms: MeetingSchedule): string => {
    let dt = '';
    if (ms) {
      dt =
        Moment(ms.meetingDate).format('DD MMM YYYY') +
        ', ' +
        Moment(ms.mdStartTime).format('HH:mm') +
        ' - ' +
        Moment(ms.mdEndTime).format('HH:mm');
    }
    return dt;
  };

  const onDeleteRequest = (requestId: number | undefined) => {
    if (requestId && sessionDetail?.deptRelation?.deptID === '100002') {
      Swal.fire({
        title: 'คุณต้องการที่จะลบข้อมูลนี้ใช่หรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่',
      }).then((res) => {
        if (res.isConfirmed) {
          deleteRequestApproval(requestId)
            .then((value) => {
              popup(true, '');
              loadData();
            })
            .catch((err) => {
              console.log('err', err);
            });
        }
      });
    }
  };

  const popup = (success: boolean, msg: string) => {
    return Swal.fire({
      text: msg,
      title: success ? 'บันทึกข้อมูลเรียบร้อยแล้ว' : 'บันทึกข้อมูลไม่สำเร็จ',
      icon: success ? 'success' : 'error',
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-between mt-3">
        <div className="col">
          <label className="title">
            <strong>My Requests</strong>
          </label>
        </div>
      </div>

      <div className="row mb-3 mt-2">
        <div className="col">
          <div className="col border border-warning"></div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-8">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    {...register('search')}
                  />
                  <button
                    className="input-group-text btn btn-primary"
                    id="search"
                  >
                    <Search fontSize="small" />
                  </button>
                </div>
              </div>
              <div className="col-4">
                <select
                  className="form-control"
                  id="status"
                  {...register('status')}
                >
                  <option value="">All</option>
                  <option value="DRAFT">DRAFT</option>
                  <option value="PENDING">PENDING</option>
                  <option value="REJECTED">REJECTED</option>
                  <option value="APPROVED">APPROVED</option>
                  <option value="MD_REJECTED">MD REJECTED</option>
                  <option value="MD_APPROVED">MD APPROVED</option>
                  <option value="MD_ACKNOWLEDGE">MD ACKNOWLEDGE</option>
                  <option value="COMPLETED">COMPLETED</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div className="col d-flex justify-content-end">
          <button
            className="btn btn-primary pb-0"
            onClick={() =>
              prop.onEdit('new', undefined, currentPage, criteria.search)
            }
          >
            <h6>
              <strong>NEW REQUEST</strong>
            </h6>
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <table className="table">
            <thead className="table-header">
              <tr>
                <th scope="col" style={{ width: '4%' }}></th>
                <th scope="col" style={{ width: '5%' }}>
                  MEETING
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  MEETING DATE
                </th>
                <th scope="col" style={{ width: '31%' }}>
                  AGENDA
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  AGENDA TYPE
                </th>
                {/* <th>REQUEST DATE</th> */}
                <th scope="col" style={{ width: '10%' }}>
                  STATUS
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  PENDING AT
                </th>
                <th scope="col" style={{ width: '5%' }}></th>
              </tr>
            </thead>
            <tbody>
              {requestApprovalList?.map((it, index) => {
                return (
                  <tr key={it.id}>
                    <td>{it.id}</td>
                    <td>{it.meetingType}</td>
                    <td>
                      {it.meetingSchedule
                        ? toDateTimeStr(it.meetingSchedule)
                        : '-'}
                    </td>
                    <td>
                      <label
                        className="is-link text-info"
                        // onClick={() =>
                        //   prop.onEdit(
                        //     'detail',
                        //     it.id?.toString(),
                        //     currentPage,
                        //     criteria.search
                        //   )
                        // }

                        onClick={() =>
                          prop.onEdit(
                            'edit',
                            it.id?.toString(),
                            currentPage,
                            criteria.search
                          )
                        }
                      >
                        {it.subject}
                      </label>
                    </td>
                    <td>{it.agendaType}</td>
                    {/* <td>
                      {it.createDate
                        ? Moment(it.createDate).format('DD MMM YYYY')
                        : '-'}
                    </td> */}
                    <td>
                      <div className={it.status}>{it.status}</div>
                    </td>
                    <td>{it.pendingAt?.nameEn}</td>
                    <td>
                      <div className="row">
                        <IconButton
                          className="mt-1 pt-1 mx-1 col-5"
                          style={{ width: '0.25rem', height: '0.25rem' }}
                          onClick={() =>
                            prop.onEdit(
                              'edit',
                              it.id?.toString(),
                              currentPage,
                              criteria.search
                            )
                          }
                        >
                          <Edit />
                        </IconButton>
                        {sessionDetail?.deptRelation?.deptID === '100002' && (
                          <IconButton
                            className="mt-1 pt-1 mx-1 col-5"
                            style={{ width: '0.25rem', height: '0.25rem' }}
                            onClick={() => onDeleteRequest(it.id)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <Pagination
            page={currentPage}
            count={page}
            variant="outlined"
            onChange={onPage}
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

type Prop = {
  onEdit: (
    view: string | undefined,
    id: string | undefined,
    page: number,
    search: string
  ) => void;
  page: number;
  search: string;
  status: RequestApprovalEnum | '';
};

type FormValue = {
  search: string;
  status: RequestApprovalEnum | '';
};

export default RequestTable;
