import { MeetingAgendaModel } from './../models/MeetingAgendaModel';
import { MeetingAdminDetailModel } from './../models/MeetingAdminDetailModel';
import { MeetingSubjectDetailModel } from './../models/MeetingSubjectDetailModel';
import { MeetingDetailModel } from './../models/MeetingDetailModel';
import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';
import { MeetingAttendeeDetailModel } from '../models/MeetingAttendeeDetailModel';

const getMeetingDetails = (
  meetingType: string
): Promise<AxiosResponse<MeetingDetailModel[]>> => {
  return DIGITAL_FLOW_API.get<MeetingDetailModel[]>(`/meeting/detail`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { meetingType },
  });
};

const getAdminMeeting = (): Promise<
  AxiosResponse<MeetingAdminDetailModel[]>
> => {
  return DIGITAL_FLOW_API.get<MeetingAdminDetailModel[]>(`/meeting/admin`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

const getSubject = (
  meetingType: string
): Promise<AxiosResponse<MeetingSubjectDetailModel[]>> => {
  return DIGITAL_FLOW_API.get<MeetingSubjectDetailModel[]>(`/meeting/subject`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { meetingType },
  });
};

const getAttendee = (
  meetingType: string
): Promise<AxiosResponse<MeetingAttendeeDetailModel[]>> => {
  return DIGITAL_FLOW_API.get<MeetingAttendeeDetailModel[]>(
    `/meeting/attendee`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
      params: { meetingType },
    }
  );
};

const getAgenda = (subjectID: number, isExecutive: boolean): Promise<AxiosResponse<MeetingAgendaModel>> => {
  return DIGITAL_FLOW_API.get<MeetingAgendaModel>(`/meeting/agenda/detail`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { subjectID: subjectID, executive: isExecutive },
  });
};

const getAttachment = (
  agendaId: string,
  meetingType: string,
  fileName: string
): Promise<AxiosResponse<any>> => {
  return DIGITAL_FLOW_API.get<any>(`/meeting/download-attachment`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
    params: { agendaId, meetingType, fileName },
    responseType: 'blob',
  });
};

export {
  getMeetingDetails,
  getAdminMeeting,
  getSubject,
  getAttendee,
  getAgenda,
  getAttachment,
};
