import React, { useEffect, useState } from 'react';
import RequestTable from '../components/RequestTable';
import RequestForm from '../components/RequestForm';
import RequestDetail from '../components/RequestDetail';
import { RequestApprovalEnum } from '../models/RequestApprovalEnum';

const MyRequest = () => {
  const [viewType, setViewType] = useState<'TABLE' | 'FORM' | 'DETAIL'>(
    'TABLE'
  );
  const [backupPage, setBackupPage] = useState(1);
  const [backupSearch, setBackupSearch] = useState('');
  const [backupStatus, setBackupStatus] = useState<RequestApprovalEnum | ''>('');
  const [viewDetail, setViewDetail] = useState<string>();
  const [id, setId] = useState<string>();

  const tableCallback = (
    view: string | undefined,
    id: string | undefined,
    page: number,
    search: string
  ) => {
    if (page) {
      setBackupPage(page);
    }

    if (view) {
      setViewDetail(view);
      setId(id);
    }

    setBackupSearch(search);
  };

  const editCallBack = () => {
    setViewDetail(undefined);
    setId(undefined);
    setViewType('TABLE');
  };

  const detailCallback = (view: string | undefined, id: string | undefined) => {
    if (view) {
      setViewDetail(view);
      setId(id);
    }
  };

  useEffect(() => {
    if (viewDetail === 'new' || viewDetail === 'edit') setViewType('FORM');
    if (viewDetail === 'detail') setViewType('DETAIL');
    if (viewDetail === 'table') setViewType('TABLE');
  }, [viewDetail]);

  return (
    <>
      {viewType === 'TABLE' && (
        <RequestTable
          onEdit={tableCallback}
          page={backupPage}
          search={backupSearch}
          status={backupStatus}
        />
      )}
      {viewType === 'FORM' && (
        <RequestForm callBack={editCallBack} view={viewDetail} id={id} />
      )}
      {viewType === 'DETAIL' && (
        <RequestDetail callBack={detailCallback} view={viewDetail} id={id} />
      )}
    </>
  );
};

export default MyRequest;
