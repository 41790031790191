export type RequestAction =
  | 'DRAFT'
  | 'SUBMIT'
  | 'APPROVE'
  | 'REJECT'
  | 'MD_REJECT'
  | 'MD_APPROVE'
  | 'CS_SUBMIT'
  | 'COMPLETE'
  | 'SAVE';
export const RequestAction = {
  DRAFT: 'DRAFT' as RequestAction,
  SUBMIT: 'SUBMIT' as RequestAction,
  APPROVE: 'APPROVE' as RequestAction,
  REJECT: 'REJECT' as RequestAction,
  MD_REJECT: 'MD_REJECT' as RequestAction,
  MD_APPROVE: 'MD_APPROVE' as RequestAction,
  CS_SUBMIT: 'CS_SUBMIT' as RequestAction,
  COMPLETE: 'COMPLETE' as RequestAction,
  SAVE: 'SAVE' as RequestAction,
};
