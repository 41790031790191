import { MeetingMDModel } from '../models/MeetingMDModel';
import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';
import { Page } from '../models/Page';

const getAllMeetingMD = (page: number, size: number): Promise<AxiosResponse<Page<MeetingMDModel>>> => {
    return DIGITAL_FLOW_API.get<Page<MeetingMDModel>>(`/meeting-md`, {
        headers: {
            Authorization: `Bearer ${authen_token}`,
        },
        params: { page, size }
    });
};

const addMeetingMD = (body: MeetingMDModel) => {
    return DIGITAL_FLOW_API.post<Page<MeetingMDModel>>(`/meeting-md/add`, body, {
        headers: {
            Authorization: `Bearer ${authen_token}`,
        }
    });
};

const updateMeetingMD = (id: number, body: MeetingMDModel) => {
    return DIGITAL_FLOW_API.put<Page<MeetingMDModel>>(`/meeting-md/${id}`, body, {
        headers: {
            Authorization: `Bearer ${authen_token}`,
        }
    });
};

const getMdProfile = () => {
    return DIGITAL_FLOW_API.get<MeetingMDModel[]>(`/meeting-md/is-md-profile`, {
        headers: {
            Authorization: `Bearer ${authen_token}`,
        }
    });
};

const deleteMeetingMD = (id: number) => {
    return DIGITAL_FLOW_API.delete<Page<MeetingMDModel>>(`/meeting-md/${id}`, {
        headers: {
            Authorization: `Bearer ${authen_token}`,
        }
    });
};

export { getAllMeetingMD, addMeetingMD, updateMeetingMD, deleteMeetingMD, getMdProfile };
