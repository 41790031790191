import { IconButton, TextField } from '@material-ui/core';
import { Close, Delete, Edit, Save } from '@material-ui/icons';
import { Pagination, Autocomplete } from '@material-ui/lab';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { MeetingMDModel } from '../../models/MeetingMDModel';
import { MeetingType } from '../../models/MeetingType';
import { getAllManagers } from '../../services/EmployeeAPI';
import {
  addMeetingMD,
  deleteMeetingMD,
  getAllMeetingMD,
  updateMeetingMD,
} from '../../services/MeetingMDAPI';
import { getMeetingType } from '../../services/RequestApprovalApi';
// import Autocomplete from '@mui/material/Autocomplete';
import { EmployeeProfileModel } from '../../models/EmployeeProfileModel';
import { ResponseEnum } from '../../utils/response.enum';
import showToast from '../../shared/toast';
import confirmPopup from '../../shared/confirmPopup';
import Swal from 'sweetalert2';
import './../../style/Input.css';

const MeetingMDList = (prop: Prop) => {
  const [currentPage, setCurrentPage] = useState<number>(prop.page);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [rows, setRows] = useState<MeetingMDModel[]>([]);
  const [employees, setEmployees] = useState<EmployeeProfileModel[]>([]);
  const [meetingType, setMeetingType] = useState<MeetingType>();

  useEffect(() => {
    Promise.all([getAllManagers(), getMeetingType()]).then(
      ([managers, meetingType]) => {
        let managersList = managers.data.filter((item) => {
          return (
            item.grade === 'M6' || item.grade === 'M7' || item.grade === 'AAA'
          );
        });
        setEmployees(managersList);
        setMeetingType(meetingType.data);
      }
    );

    getAllMeetingMD(currentPage - 1, pageSize)
      .then((res) => {
        if (res.data.content !== undefined) {
          const data = res.data.content;
          setRows([...data]);
        }
      })
      .catch(() => {
        setRows([]);
      });
  }, [currentPage]);

  const onPage = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handleAddRow = () => {
    const item: MeetingMDModel = {
      id: 0,
      meetingType: '',
      empId: '',
      empName: '',
      isEdit: true,
    };
    const currentRows = [...rows];
    currentRows.push(item);
    setRows([...currentRows]);
  };

  const handleSave = (index: number) => {
    rows[index].isEdit = false;
    setRows([...rows]);

    const id = rows[index].id ? rows[index].id : undefined;

    const d: MeetingMDModel = rows[index]
    if (id !== undefined) {
      updateMeetingMD(id, d)
        .then((res) => {
          if (res.status === 200) {
            setRows([...rows]);
            popup(true, '');
          } else {
            const currentRows = [...rows];
            currentRows.splice(index, 1);
            setRows([...currentRows]);
            popup(false, '');
          }
        })
        .catch((err) => {
          console.log(err);
          popup(false, '');
        });
    } else {
      addMeetingMD(d)
        .then((res) => {
          if (res.status === 200) {
            setRows([...rows]);
            popup(true, '');
          } else {
            const currentRows = [...rows];
            currentRows.splice(index, 1);
            setRows([...currentRows]);
            popup(false, '');
          }
        })
        .catch((err) => {
          console.log(err);
          popup(false, '');
        });
    }
  };

  const handleClose = (index: number) => {
    rows[index].isEdit = false;
    setRows([...rows]);

    if (rows[index].id === 0) {
      const currentRows = [...rows];
      currentRows.splice(index, 1);
      setRows([...currentRows]);
    }
  };

  const handleRemoveRow = (id: number | undefined, index: number) => {
    if (id) {
      const isConfirm = confirmPopup(
        'Are you sure?',
        'You want to delete this row.'
      );
      isConfirm.then((res) => {
        if (res.isConfirmed) {
          deleteMeetingMD(id).then(
            () => {
              const currentRows = [...rows];
              currentRows.splice(index, 1);
              setRows([...currentRows]);
              popup(true, '');
            },
            () => {
              popup(false, '');
            }
          );
        } else if (res.dismiss === Swal.DismissReason.cancel) {
        }
      });
    } else {
      const currentRows = [...rows];
      currentRows.splice(index, 1);
      setRows([...currentRows]);
      popup(false, '');
    }
  };

  const popup = (success: boolean, msg: string) => {
    return Swal.fire({
      text: msg,
      title: success ? 'บันทึกข้อมูลเรียบร้อยแล้ว' : 'บันทึกข้อมูลไม่สำเร็จ',
      icon: success ? 'success' : 'error',
      confirmButtonText: 'Close',
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });
  };

  const handleEdit = (index: number) => {
    rows[index].isEdit = true;
    setRows([...rows]);
  };

  const handleSelectChange = (
    e: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const list = [...rows];
    (list as any)[index][name] = value;
    setRows(list);
  };

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, checked } = e.target;
    const list = [...rows];
    (list as any)[index][name] = checked;
    setRows(list);
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-between mt-3">
        <div className="col">
          <label className="title">
            <strong>รายชื่อ MD</strong>
          </label>
        </div>
      </div>

      <div className="row mb-3 mt-2">
        <div className="col">
          <div className="col border border-warning"></div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary pb-0" onClick={handleAddRow}>
            <h6>
              <strong>ADD</strong>
            </h6>
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <table className="table text-center">
          <thead className="table-header">
            <tr>
              <th className="col-1">No.</th>
              <th>Meeting Type</th>
              <th>MD Name</th>
              <th>Affiliate</th>
              <th className="col-1"></th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="input-align-center">
                    {row.isEdit ? (
                      <select
                        className="form-select"
                        onChange={(e) => handleSelectChange(e, index)}
                        name="meetingType"
                        value={row.meetingType}
                        style={{ width: 250 }}
                      >
                        <option value={undefined}>-- กรุณาระบุ --</option>
                        {meetingType?.meetingTypes?.map((value) => (
                          <option key={value.id} value={value.id}>
                            {value.id}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div>{row.meetingType}</div>
                    )}
                  </td>
                  <td className="input-align-center">
                    {/* <Autocomplete
                      id="manager"
                      fullWidth
                      // options={employees.map((option) => option.nameEn)}
                      options={employees}
                      getOptionLabel={(option: any) => option.nameEn}
                      getOptionSelected={(option: any, value: any) => option.empID === value?.empID}
                      // sx={{ width: 300 }}
                      size="small"
                      // value={rows[index].empName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // InputProps={{
                          //   ...params.InputProps,
                          //   type: 'search',
                          // }}
                          variant="outlined"
                        />
                      )}
                      disabled={!row.isEdit}
                    /> */}
                    {row.isEdit ? (
                      <select
                        className="form-select"
                        onChange={(e) => handleSelectChange(e, index)}
                        name="empId"
                        value={row.empId}
                        style={{ width: 250 }}
                      >
                        <option value={undefined}>-- Select MD --</option>
                        {employees?.map((emp) => (
                          <option key={emp.empID} value={emp.empID}>
                            {emp.nameEn}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div>
                        {employees?.find((e) => e.empID === row.empId)?.nameEn}
                      </div>
                    )}
                  </td>
                  <td>
                    <input type='checkbox' id={"affi_" + index} name="affiliatedFlag" className="form-check-input" checked={row.affiliatedFlag} disabled={!row.isEdit}
                           onChange={(e) => handleCheckboxChange(e, index)}
                    />
                  </td>
                  <td>
                    {row.isEdit ? (
                      <div>
                        <IconButton
                          className="mt-1 pt-1 mx-2"
                          style={{ width: '0.25rem', height: '0.25rem' }}
                          onClick={() => handleSave(index)}
                        >
                          <Save />
                        </IconButton>

                        <IconButton
                          className="mt-1 pt-1 mx-2"
                          style={{ width: '0.25rem', height: '0.25rem' }}
                          onClick={() => handleClose(index)}
                        >
                          <Close />
                        </IconButton>
                      </div>
                    ) : (
                      <div>
                        <IconButton
                          className="mt-1 pt-1 mx-2"
                          style={{ width: '0.25rem', height: '0.25rem' }}
                          onClick={() => handleEdit(index)}
                        >
                          <Edit />
                        </IconButton>

                        <IconButton
                          className="mt-1 pt-1 mx-2"
                          style={{ width: '0.25rem', height: '0.25rem' }}
                          onClick={() => handleRemoveRow(row.id, index)}
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="row mb-3">
        <div className="col">
          <Pagination
            page={currentPage}
            count={page}
            variant="outlined"
            onChange={onPage}
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

type Prop = {
  page: number;
};

export default MeetingMDList;
