import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  getAgendaType,
  getById,
  getMeetingType,
  getPendingApproveList,
} from '../services/RequestApprovalApi';
import { Pagination, TextField } from '@mui/material';
import { RequestApproval } from '../models/RequestApproval';
import { useForm } from 'react-hook-form';
import { AgendaType } from '../models/AgendaType';
import Moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { count } from 'console';
import { MeetingTypeDetail } from '../models/MeetingTypeDetail';
import RequestForm from '../components/RequestForm';
import { useHistory, useParams } from 'react-router-dom';

const PendingForApprove = () => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState<number>(page | 1);

  const [pendingApproveList, setPendingApproveList] = useState<
    RequestApproval[]
  >([]);
  const [agendaTypeList, setAgendaTypeList] = useState<AgendaType | null>();
  const [meetingTypeDropdown, setMeetingTypeDropdown] =
    useState<MeetingTypeDetail[]>();
  const [selectedMeetingType, setSelectedMeetingType] = useState<
    string | undefined
  >(undefined);

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(new Date().setMonth(new Date().getMonth() + 2))
  );
  const [criteria, setCriteria] = useState<SearchCriteria>({
    start: new Date(),
    end: new Date(new Date().setMonth(new Date().getMonth() + 2)),
    agendaType: null,
  });

  const { register, handleSubmit } = useForm<SearchCriteria>();

  const [viewType, setViewType] = useState<'TABLE' | 'FORM'>('TABLE');
  const [viewDetail, setViewDetail] = useState<string>();
  const [id, setId] = useState<string>();
  const requestId: { id: string } = useParams();
  const history = useHistory();

  const onPage = (event: any, value: number) => {
    setCurrentPage(value);
  };

  function loadList(page: number, count: number, criteria: SearchCriteria) {
    getPendingApproveList(
      page - 1,
      count,
      criteria.start,
      criteria.end,
      criteria.agendaType
    ).then((res) => {
      setPage(res.data?.totalElements || 1);
      if (res.data?.content) {
        const data = res.data.content;
        setPendingApproveList([...data]);
      }
    });
  }

  useEffect(() => {
    loadList(currentPage, pageSize, criteria);
    getMeetingType()
      .then((res) => {
        setMeetingTypeDropdown(res.data?.meetingTypes);
      })
      .catch(() => {
        setMeetingTypeDropdown([]);
      });

    if (requestId.id) {
      let requestDetailId = Number(requestId.id);
      if (!isNaN(requestDetailId)) {
        getById(requestDetailId)
          .then((value) => {
            if (value.data.id) {
              onRouteToApprovePage('FORM', Number(requestId.id));
            } else {
              history.replace('/pending-for-approval');
            }
          })
          .catch((err) => {
            history.replace('/pending-for-approval');
          });
      }
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (selectedMeetingType !== undefined) {
      getAgendaType(selectedMeetingType)
        .then((value) => {
          setAgendaTypeList(value.data);
        })
        .catch((err) => {
          setAgendaTypeList(null);
        });
    }
  }, [selectedMeetingType]);

  const editCallBack = () => {
    history.replace('/pending-for-approval');
    setViewDetail(undefined);
    setId(undefined);
    setViewType('TABLE');
    loadList(currentPage, pageSize, criteria);
  };

  const onRouteToApprovePage = (
    view: string | undefined,
    id: number | undefined
  ) => {
    setViewDetail(view);
    if (view === 'FORM') {
      setViewType('FORM');
    }
    setId(id?.toString());
    setPage(currentPage);
  };

  const renderPendingList = () => {
    return (
      <>
        {pendingApproveList.map((p: RequestApproval) => {
          console.log('pendingApproveList', pendingApproveList);

          return (
            <tr key={p.id}>
              <th scope="row">{p.id}</th>
              <td>{p.meetingType}</td>
              <td>
                {Moment(p.meetingSchedule?.meetingDate).format('DD MMM YYYY')}
              </td>
              <td
                className="is-link text-info"
                onClick={() => onRouteToApprovePage('FORM', p.id)}
              >
                {p.subject}
              </td>
              <td>{p.agendaType}</td>
              <td>{p.requester}</td>
              {/* <td>{Moment(p.requestDate).format('DD MMM YYYY')}</td> */}
              <td>
                <div className={p.status}>{p.status}</div>
              </td>
              <td>{p.pendingAt?.nameTh}</td>
            </tr>
          );
        })}
      </>
    );
  };

  const onSubmit = async (data: SearchCriteria) => {
    setCriteria({ ...criteria });
    loadList(currentPage, pageSize, criteria);
  };

  const onStartDateChange = (value: Date | null) => {
    setStartDate(value);
    criteria.start = value ? value : null;
    setCriteria({ ...criteria });
  };

  const onEndDateChange = (value: Date | null) => {
    setEndDate(value);
    criteria.end = value ? value : null;
    setCriteria({ ...criteria });
  };

  const onAgendaTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    criteria.agendaType = e.target.value !== undefined ? e.target.value : null;
    setCriteria({ ...criteria });
  };

  const onMeetingTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setSelectedMeetingType(e.target.value);
    }
  };

  return (
    <>
      {viewType === 'TABLE' && (
        <div className="container-fluid">
          <div className="row justify-content-between mt-3">
            <div className="col">
              <label className="title">
                <strong>Pending For approve</strong>
              </label>
            </div>
          </div>

          <div className="row mb-3 mt-2">
            <div className="col">
              <div className="col border border-warning"></div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row justify-content-start mb-3">
              <label className="col-sm-auto d-flex justify-content-start col-form-label">
                <b>Meeting Date</b>
              </label>
              <div className="col-sm-auto">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={startDate}
                    onChange={(newValue) => onStartDateChange(newValue)}
                    inputFormat="dd MMM yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        style={{ width: 150 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <label className="col-sm-auto d-flex justify-content-start col-form-label">
                <b>To</b>
              </label>
              <div className="col-sm-auto">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={endDate}
                    onChange={(newValue) => onEndDateChange(newValue)}
                    inputFormat="dd MMM yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        style={{ width: 150 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <label className="col-sm-auto d-flex justify-content-start col-form-label">
                <b>Meeting Type</b>
              </label>
              <div className="col-sm-auto">
                <select
                  className="form-select"
                  onChange={(e) => onMeetingTypeChange(e)}
                  style={{ width: 200 }}
                >
                  <option value={0}>-- เลือกประเภท --</option>
                  {meetingTypeDropdown?.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.type}
                    </option>
                  ))}
                </select>
              </div>

              <label className="col-sm-auto d-flex justify-content-start col-form-label">
                <b>วาระ</b>
              </label>
              <div className="col-sm-auto">
                <select
                  className="form-select"
                  onChange={(e) => onAgendaTypeChange(e)}
                  style={{ width: 250 }}
                  disabled={selectedMeetingType === undefined}
                >
                  <option value={undefined}>-- เลือกประเภท --</option>
                  {agendaTypeList?.agendaTypes?.map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.descr}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-auto">
                <button className="btn btn-primary" type="submit">
                  Search
                </button>
              </div>
            </div>
          </form>
          <table className="table mt-2">
            <thead className="table-header">
              <tr>
                <th scope="col" style={{ width: '4%' }}></th>
                <th scope="col" style={{ width: '10%' }}>
                  COMPANY
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  MEETING DATE
                </th>
                <th scope="col" style={{ width: '16%' }}>
                  AGENDA
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  AGENDA TYPE
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  REQUESTER
                </th>
                {/* <th scope="col">REQUEST DATE</th> */}
                <th scope="col" style={{ width: '10%' }}>
                  STATUS
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  PENDING AT
                </th>
              </tr>
            </thead>
            <tbody>{renderPendingList()}</tbody>
          </table>
          <Pagination
            page={currentPage}
            count={page}
            variant="outlined"
            onChange={onPage}
            shape="rounded"
          />
        </div>
      )}
      {viewType === 'FORM' && (
        <RequestForm callBack={editCallBack} view={viewDetail} id={id} />
      )}
    </>
  );
};

type Prop = {
  onEdit: (
    view: string | undefined,
    id: string | undefined,
    page: number,
    search: string
  ) => void;
};

type SearchCriteria = {
  start: Date | null;
  end: Date | null;
  agendaType: string | null;
};

export default PendingForApprove;
