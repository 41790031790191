import axios from 'axios';

const DIGITAL_FLOW_API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
  headers: {
    'env': process.env.REACT_APP_ENV || ''
  }
});

DIGITAL_FLOW_API.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    console.log('Err:', err);
    if (err.response && err.response.status === 401) {
      const backPath = window.location.href;
      console.log('backPath:', backPath);

      localStorage.setItem('back_path', backPath);
      window.location.href = `${process.env.REACT_APP_API_ENDPOINT || ''}/oauth2${process.env.REACT_APP_ENV || ''}`;
    } else {
      return Promise.reject(err);
    }
  }
);


const authen_token = localStorage.getItem('digital_flow_token');

export { DIGITAL_FLOW_API, authen_token };
