import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';
import { AxiosResponse } from 'axios';
import { EmployeeProfileModel } from '../models/EmployeeProfileModel';

const getAllManagers = (): Promise<AxiosResponse<EmployeeProfileModel[]>> => {
  return DIGITAL_FLOW_API.get<EmployeeProfileModel[]>(`/employee/managers`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

const getSession = (): Promise<AxiosResponse<EmployeeProfileModel>> => {
  return DIGITAL_FLOW_API.get<EmployeeProfileModel>(
    `/employee/session-profile`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
    }
  );
};

const getProfileByEmail = (
  email: string
): Promise<AxiosResponse<EmployeeProfileModel>> => {
  return DIGITAL_FLOW_API.get<EmployeeProfileModel>(
    `/employee/profiles-by-email/${email}`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
    }
  );
};

const getProfileByEmpId = (
  empId: string
): Promise<AxiosResponse<EmployeeProfileModel>> => {
  return DIGITAL_FLOW_API.get<EmployeeProfileModel>(
    `/employee/profiles-by-emp-id/${empId}`,
    {
      headers: {
        Authorization: `Bearer ${authen_token}`,
      },
    }
  );
};

export { getAllManagers, getSession, getProfileByEmail, getProfileByEmpId };
