import { AxiosResponse } from 'axios';
import { authen_token, DIGITAL_FLOW_API } from './DigitalFlowBaseAPI';

const isAdmin = (): Promise<AxiosResponse<boolean>> => {
  return DIGITAL_FLOW_API.get(`/is-admin`, {
    headers: {
      Authorization: `Bearer ${authen_token}`,
    },
  });
};

const loginAdmin = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    isAdmin()
      .then((value: any) => {
        resolve(value.data);
      })
      .catch((err) => {
        console.error('ERR: ', err);
        if (err.response && 'INVALID SESSION' === err.response.data.message) {
          redirectLogin();
        } else {

        }
      });
  });
};

const redirectLogin = (): void => {
  localStorage.setItem('back_path', window.location.href);
  window.location.href = (process.env.REACT_APP_API_ENDPOINT || '') + '/oauth2' +
    (process.env.REACT_APP_ENV || '');
};

export { isAdmin, loginAdmin };
