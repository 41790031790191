const r = {
  admin: [
    {
      to: '/my-request',
      label: 'My Requests',
    },
    {
      to: '/admin/meeting-schedule',
      label: 'Meeting Schedule',
    },
    {
      to: '/admin/pending-for-approval',
      label: 'Pending For Approval',
    },
    {
      to: '/admin/board-meeting',
      label: 'Board Meeting',
    },
  ],
  user: [
    {
      to: '/pending-for-approval',
      label: 'Pending For Approval',
    },
    {
      to: '/my-request',
      label: 'My Requests',
    },
  ],
};

export { r };
